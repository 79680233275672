import { parsePhoneNumber } from 'libphonenumber-js';
import { type ComponentPropsWithoutRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOnMount } from '~/hooks/useOnMount';
import type { CommonFieldProps } from '~/modules/ui/fields/common-field-props';
import {
  FormControl,
  FormDescription,
  FormField,
  FormHint,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/modules/ui/primitives/form';
import { PhoneIcon } from '~/modules/ui/primitives/icon';
import { Input } from '~/modules/ui/primitives/input';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
import { Country, countryByCode } from '~/utils/countryCode';
import { toInternationalPhoneNumber } from '~/utils/format';

function phoneNumberToFlag(phoneNumber: string | undefined) {
  if (!phoneNumber) {
    return null;
  }

  try {
    const parsed = parsePhoneNumber(phoneNumber, 'US');

    if (parsed && parsed.country) {
      const country: Country | undefined =
        countryByCode[parsed.country as unknown as keyof typeof countryByCode];

      return country.flag ?? null;
    }
  } catch (e) {
    return null;
  }

  return null;
}

type PhoneInputField = CommonFieldProps &
  ComponentPropsWithoutRef<typeof Input>;

export function PhoneInputField({
  description,
  label,
  name,
  helperLabel,
  ...props
}: PhoneInputField) {
  const form = useFormContext();
  const [disabled, setDisabled] = useState(true);

  useOnMount(() => {
    // Sort of a hack to override the default value with a formatted value on the first render.
    form.setValue(name, toInternationalPhoneNumber(form.getValues(name)));
    // If the form gets filled in too fast it might end up getting cleared prior
    // to a circular race of values being updated and methods being executed.
    // This realistically could only happen with automation.  A user should
    // never see this state of disabled. The props.disabled state is applied
    // below this value in the `Input` component so any override will be handled
    // there.
    setDisabled(false);
  });

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field: { onBlur, ...field } }) => {
        const flag = phoneNumberToFlag(field.value);

        return (
          <FormItem>
            <VStack gap="2">
              {(label || helperLabel) && (
                <HStack alignItems="center">
                  {label && <FormLabel>{label}</FormLabel>}
                  {helperLabel && <FormHint>{helperLabel}</FormHint>}
                </HStack>
              )}

              <FormControl>
                <Input
                  type="tel"
                  placeholder="+1 (555) 555-1234"
                  disabled={disabled}
                  {...props}
                  onBlur={(value) => {
                    // On blur, we format the phone number.
                    field.onChange(
                      toInternationalPhoneNumber(value.target.value),
                    );
                    onBlur?.();
                  }}
                  {...field}
                >
                  <VStack
                    className="t2-size-5 t2-absolute t2-top-2.5 t2-left-3 t2-overflow-hidden"
                    alignItems="center"
                    justifyContent="center"
                    key={flag}
                  >
                    {flag ? flag : <PhoneIcon className="t2-size-5" />}
                  </VStack>
                </Input>
              </FormControl>

              {description && <FormDescription>{description}</FormDescription>}
              <FormMessage />
            </VStack>
          </FormItem>
        );
      }}
    />
  );
}
