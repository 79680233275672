/*
 * Shared constants for triggering messages via url parameters. Useful for
 * handling redirects that can may end up on several different pages of the app,
 * but need to provide specific feedback to the user.
 */

export const MESSAGE_QUERY_PARAM_KEY = 'tola-message';
export const MESSAGE_QUERY_PARAM_VALUES = [
  'xero-success',
  'quickbooks-success',
  'accounting-error',
] as const;

export type MessageQueryParamValue =
  (typeof MESSAGE_QUERY_PARAM_VALUES)[number];
