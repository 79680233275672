import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ArrowRightIcon = createIcon('ArrowRightIcon', [
  [
    'path',
    {
      d: 'M13.75 6.75L19.25 12L13.75 17.25',
      key: '19drep',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M19 12H4.75',
      key: '6hqd1l',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ArrowRightIcon;
