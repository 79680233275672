import { m } from 'framer-motion';
import { cn } from '~/modules/ui/cva';

function LoadingIndicator({ className, ...props }: { className?: string }) {
  return (
    <div
      className={cn('t2-size-10 t2-grid t2-place-items-center', className)}
      data-test-id="spinner"
      {...props}
    >
      <m.div
        className="t2-size-2 t2-bg-current t2-rounded-full"
        animate={{
          y: ['-25%', '100%'],
          scaleY: [1, 1.3],
        }}
        transition={{
          duration: 0.4,
          ease: [0.7, 0, 1, 1],
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
    </div>
  );
}

export { LoadingIndicator };
