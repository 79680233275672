import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const WarningTriangleIcon = createIcon('WarningTriangleIcon', [
  [
    'path',
    {
      d: 'M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z',
      key: 'ctjtuz',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 10V12',
      key: '1g4p2j',
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'circle',
    {
      cx: '12',
      cy: '16',
      r: '1',
      strokeWidth: 0,
      key: '1vxudn',
      fill: 'currentcolor',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default WarningTriangleIcon;
