import { useCallback } from 'react';
import { useEventListener } from '~/hooks/useEventListener';

type UseKeyDownOpts = {
  key: string | string[];
  handler: (event: KeyboardEvent) => void;
  /**
   * @default true
   */
  enabled?: boolean;
};

const matchesKey = (optsKey: UseKeyDownOpts['key'], pressedKey: string) => {
  if (Array.isArray(optsKey)) {
    return optsKey.includes(pressedKey);
  }
  return optsKey === pressedKey;
};

export function useKeyDown(opts: UseKeyDownOpts) {
  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (!matchesKey(opts.key, event.key)) {
        return;
      }

      if (event.defaultPrevented) {
        return;
      }

      opts.handler(event);
    },
    [opts],
  );

  useEventListener({
    type: 'keydown',
    listener,
    enabled: opts.enabled ?? true,
  });
}
