import * as React from 'react';
import { cn } from '~/modules/ui/cva';
import { HStack } from '~/modules/ui/primitives/stack';

function Object({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <HStack
      className={cn(
        '[&>svg]:t2-size-6 [&>svg]:t2-text-foreground-secondary t2-max-w-full t2-min-w-0',
        className,
      )}
      alignItems="center"
      gap="2"
      {...props}
    />
  );
}

interface ObjectTitleProps extends React.HTMLAttributes<HTMLSpanElement> {
  fallback?: string;
}

function ObjectTitle({ className, fallback, ...props }: ObjectTitleProps) {
  if (!props.children && fallback) {
    return (
      <span className="t2-text-foreground-secondary/50 t2-leading-6">
        {fallback}
      </span>
    );
  }

  return (
    <span
      className={cn('t2-leading-6 t2-text-foreground', className)}
      {...props}
    />
  );
}

function ObjectDescription({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn('t2-leading-6 t2-text-foreground-secondary', className)}
      {...props}
    />
  );
}

export { Object, ObjectTitle, ObjectDescription };
