import { cx } from '~/utils/css-utils';

export interface SpinnerProps {
  style?: 'blue' | 'black' | 'white';
  className?: string;
}

export const Spinner = (props: SpinnerProps) => {
  const { style = 'blue', className } = props;

  return (
    <svg
      className={cx('animate-spin stroke-2', className ?? 'w-10')}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      data-test-id="spinner"
    >
      <circle
        className={cx(
          style === 'blue' && 'stroke-blue-50',
          style === 'black' && 'stroke-black/10',
          style === 'white' && 'stroke-white/30',
        )}
        cx="12"
        cy="12"
        r="10"
        // 1/4 of the circumference of a 10 radius circle = 15.71
        // 3/4 of the circumference of a 10 radius circle = 47.12
        strokeDasharray="0 15.71 47.12"
      />
      <circle
        className={cx(
          style === 'blue' && 'stroke-blue',
          style === 'black' && 'stroke-black',
          style === 'white' && 'stroke-white',
        )}
        cx="12"
        cy="12"
        r="10"
        // 1/4 of the circumference of a 10 radius circle = 15.71
        // 3/4 of the circumference of a 10 radius circle = 47.12
        strokeDasharray="15.71 47.12"
      />
    </svg>
  );
};

export const PageSpinner = () => (
  <div className="grid h-full w-full place-items-center p-4">
    <Spinner className="w-10" />
  </div>
);
