import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const CopyIcon = createIcon('CopyIcon', [
  [
    'path',
    {
      d: 'M6.5 15.25V15.25C5.5335 15.25 4.75 14.4665 4.75 13.5V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H13.5C14.4665 4.75 15.25 5.5335 15.25 6.5V6.5',
      key: '1ioo0q',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'rect',
    {
      x: '8.75',
      y: '8.75',
      width: '10.5',
      height: '10.5',
      strokeWidth: '1.5',
      key: '1ywfng',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default CopyIcon;
