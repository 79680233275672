import { type RefObject, useEffect } from 'react';
import { useEventCallback } from '~/hooks/useEventCallback';

export function useOnOutsideInteraction<
  TType extends keyof WindowEventMap,
  TRef extends HTMLElement = HTMLElement,
>(props: {
  ref: RefObject<TRef> | RefObject<TRef>[];
  element?: HTMLElement | Document | Window | EventTarget | null | undefined;
  type: TType;
  listener: (event: WindowEventMap[TType]) => void;
  options?: boolean | AddEventListenerOptions;
  enabled?: boolean;
}): void {
  const { type, options, ref, enabled = true } = props;

  const listener = useEventCallback(props.listener);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const element = props.element ?? window;
    function handler(event: WindowEventMap[TType]) {
      const target = event.target as Node;

      // Do nothing if the target is not connected element with document
      if (!target || !target.isConnected) {
        return;
      }

      const isOutside = Array.isArray(ref)
        ? ref
            .filter((r) => Boolean(r.current))
            .every((r) => r.current && !r.current.contains(target))
        : ref.current && !ref.current.contains(target);

      if (isOutside) {
        listener(event);
      }
    }

    // biome-ignore lint/suspicious/noExplicitAny: ok
    element.addEventListener(type, handler as any, options);
    // biome-ignore lint/suspicious/noExplicitAny: ok
    return () => element.removeEventListener(type, handler as any, options);
  }, [props.element, ref, type, options, enabled, listener]);
}
