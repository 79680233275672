import { useKeyDown } from '~/hooks/useKeyDown';

export function useOnEscape(opts: {
  handler: (event: KeyboardEvent) => void;
  /**
   * @default true
   */
  enabled?: boolean;
}) {
  useKeyDown({
    key: 'Escape',
    enabled: opts.enabled ?? true,
    handler: opts.handler,
  });
}
