'use client';

import { ComponentPropsWithoutRef } from 'react';
import { useIntercom } from 'react-use-intercom';
import { Button } from '~/modules/ui/primitives/button';

export function SupportButton(
  props: Omit<ComponentPropsWithoutRef<typeof Button>, 'onClick'>,
) {
  const intercom = useIntercom();

  return (
    <Button intent="secondary" onClick={() => intercom.show()} {...props}>
      {props.children ?? 'Contact support'}
    </Button>
  );
}
