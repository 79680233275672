import { createTRPCReact } from '@trpc/react-query';

/**
 * Untyped `createTRPCReact` instance
 */
export const trpcBase = createTRPCReact({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        // Invalidate all queries `onSuccess`

        // Order here matters.
        // Invalidation is triggered after the user's `onSuccess`.
        // Enables route changes in `onSuccess` without having a flash of content change whilst redirecting.
        await opts.originalFn();
        if (opts.meta.noInvalidate === true) {
          return;
        }
        await opts.queryClient.invalidateQueries();
      },
    },
  },
});
