import { datadogLogs } from '@datadog/browser-logs';

interface Logger {
  debug(message: string, messageContext?: object, error?: Error): void;
  info(message: string, messageContext?: object, error?: Error): void;
  warn(message: string, messageContext?: object, error?: Error): void;
  error(message: string, messageContext?: object, error?: Error): void;
}

export const createLogger = datadogLogs.createLogger as (
  name: string,
) => Logger;
