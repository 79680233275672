type ValueOf<
  TObjectType,
  TValueType extends keyof TObjectType = keyof TObjectType,
> = TObjectType[TValueType];

export type PermissionDefinitionAction = BooleanConstructor | string[];

export type AnyPermissionDefinition = Record<
  string,
  Record<string, PermissionDefinitionAction>
>;

export function createPermissions<
  TFilter extends string,
  TScope extends Record<string, TFilter[] | BooleanConstructor>,
  TDef extends Record<string, TScope>,
>(record: TDef) {
  return record;
}

export type inferPermissionScope<TDef extends AnyPermissionDefinition> =
  ValueOf<{
    [TEntity in keyof TDef]: `${TEntity & string}.${keyof TDef[TEntity] &
      string}`;
  }>;
