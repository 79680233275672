/**
 * This file handles any cases where we want to do special treatment of navigation changes.
 * For instance, if the user has unsaved changes, we want to prompt them before they navigate away.
 * We also want to do a hard reload if the app version changes, but suppress if we already have a prompt for the prior mentioned case.
 */
import type { TRPCLink } from '@trpc/client';
import { tap } from '@trpc/server/observable';
import { Router } from 'next/router';
import { useEffect } from 'react';
import { PublicConfig } from '~/app/_utils/publicConfig';
import type { AppRouter } from '~/server/routers/_app';

let initVersion = '';
let shouldReloadOnNextNavigation = false;

/**
 * We track if we have a `usePromptOnNavigation`-hook enabled.
 * This is to avoid double prompts together with `useHardNavigationOnVersionChange`.
 */
let promptOnNavigationEnabled = false;

/**
 * This is how you abort a navigation in Next.js.
 * It's a bit hacky, but it's the only way to do it.
 */
const abortNavigation = () => {
  Router.events.emit('routeChangeError');
  throw new Error('routeChange aborted - ignore this error.');
};
export const usePromptOnNavigation = (
  enabled: boolean,
  text = 'You have unsaved changes - are you sure you want to continue?',
) => {
  useEffect(
    function promptOnRouteChange() {
      if (!enabled) {
        return;
      }

      if (promptOnNavigationEnabled) {
        const message = 'Only one navigation prompt can be enabled at a time';
        console.error(message);
        return;
      }

      const handleWindowClose = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        return (e.returnValue = text);
      };
      const handleBrowseAway = () => {
        if (window.confirm(text)) {
          return;
        }
        abortNavigation();
      };
      window.addEventListener('beforeunload', handleWindowClose);
      Router.events.on('routeChangeStart', handleBrowseAway);

      promptOnNavigationEnabled = true;
      return () => {
        promptOnNavigationEnabled = false;
        window.removeEventListener('beforeunload', handleWindowClose);
        Router.events.off('routeChangeStart', handleBrowseAway);
      };
    },
    [enabled, text],
  );
};

/**
 * This is a hook that will do a hard reload if the app version changes.
 * This should be included exactly once in the app.
 */
export function useHardNavigationOnVersionChange(publicConfig: PublicConfig) {
  if (!initVersion) {
    initVersion = publicConfig.GIT_COMMIT;
  }
  useEffect(() => {
    const hardLinkOnNavigationChange = (target: string) => {
      if (!shouldReloadOnNextNavigation || promptOnNavigationEnabled) {
        // Suppressed if we already have a prompt in order to avoid double prompts
        return;
      }
      console.log('Doing hard navigation change due to version change');

      window.location.href = target;
      abortNavigation();
    };
    Router.events.on('routeChangeStart', hardLinkOnNavigationChange);
    return () => {
      Router.events.off('routeChangeStart', hardLinkOnNavigationChange);
    };
  }, []);
}

/**
 * This is a trpc link that will detect if the app version changes.
 * It will store the version in a variable that can be used by the `useHardNavigationOnVersionChange`-hook.
 * @see https://trpc.io/docs/links#creating-a-custom-link
 */
export const detectVersionChangeLink: TRPCLink<AppRouter> = () => {
  function isResponse(obj: unknown): obj is Response {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      typeof (obj as Record<string, unknown>).status === 'number'
    );
  }
  return ({ next, op }) => {
    return next(op).pipe(
      tap({
        next(value) {
          const res = value.context?.response;
          if (isResponse(res)) {
            const hash = res.headers.get('x-app-hash');
            if (initVersion && hash && hash !== initVersion) {
              console.log(
                { hash, initVersion },
                'ℹ️ Detected version change, next navigation will reload the page',
              );
              shouldReloadOnNextNavigation = true;
            }
          }
        },
      }),
    );
  };
};
