import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const AsteriskIcon = createIcon('AsteriskIcon', [
  [
    'path',
    {
      d: 'M12 4.75V19.25',
      key: '1gsozm',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M18.2501 8.74994L5.75 15.2501',
      key: 'fvf7l7',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M18.2498 15.2503L5.74976 8.75012',
      key: '1viclw',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default AsteriskIcon;
