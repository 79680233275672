import { Temporal, toTemporalInstant } from '@js-temporal/polyfill';
import type { Simplify } from 'type-fest';
import { z } from 'zod';
import { assertUnreachable } from '~/utils/assertUnreachable';
import {
  toMonthDayString,
  toShortMonthDayAndTimeString,
  toShortMonthDayString,
} from './format';

export const UTC = new Temporal.TimeZone('UTC');

type PropagateUndefined<TType, TNewType> = TType extends undefined
  ? TNewType | undefined
  : TNewType;

type PropagateNull<TType, TNewType> = TType extends null
  ? TNewType | null
  : TNewType;
/** Propagate methods is needed to be able to do this:
 * { date: Date} => {date : PlainDate}
 * { date ?: Date} => {date ?: PlainDate}
 * { date ?: Date | null} => {date ?: PlainDate | null}
 * { date : Date | undefined} => {date : PlainDate | undefined}
 *
 * To be noted that if tsconfig exactOptionalPropertyTypes
 * https://www.typescriptlang.org/tsconfig#exactOptionalPropertyTypes
 * is not set to true, optional gets "| undefined" added to their types
 */
type PropagateNullOrOptional<T, TNewType> = PropagateNull<
  T,
  PropagateUndefined<T, TNewType>
>;

type ReplaceType<T, TKeys extends keyof T, TNewType> = {
  [K in keyof T]: K extends TKeys
    ? PropagateNullOrOptional<T[K], TNewType>
    : T[K];
};

function replace<
  TOldType,
  TNewType,
  T extends { [P in TKeys]?: TOldType | undefined | null },
  TKeys extends keyof T,
>(args: {
  object: T;
  dateKeysToConvert: TKeys[];
  converter: (toReplace: TOldType) => TNewType;
}): ReplaceType<T, TKeys, TNewType> {
  const converted = args.dateKeysToConvert.reduce(
    (a, key) => {
      // Handle optional
      if (!(key in args.object)) {
        return a;
      }
      const toReplace = args.object[key];
      return {
        ...a,
        [key]: toReplace && args.converter(toReplace as TOldType),
      };
    },
    {} as ReplaceType<T, TKeys, TNewType>,
  );

  return {
    ...args.object,
    ...converted,
  };
}

export type WithPlainDate<T, TKeys extends keyof T> = ReplaceType<
  T,
  TKeys,
  Temporal.PlainDate
>;

export type WithInstant<T, TKeys extends keyof T> = ReplaceType<
  T,
  TKeys,
  Temporal.Instant
>;

export type WithPlainDateTime<T, TKeys extends keyof T> = ReplaceType<
  T,
  TKeys,
  Temporal.PlainDateTime
>;

export type WithJsDate<T, TKeys extends keyof T> = ReplaceType<T, TKeys, Date>;

type WithPlainDateString<T, TKeys extends keyof T> = ReplaceType<
  T,
  TKeys,
  string
>;

export function toPlainDate(date: Date | string) {
  if (typeof date === 'string') {
    return Temporal.PlainDate.from(date);
  }
  return Temporal.PlainDate.from(date.toISOString().split('T')[0] as string);
}

/**
 * Create a date `YYYY-MM-DD` string from a `Date`
 */
export function toPlainDateString(date: Date | Temporal.PlainDate) {
  return date.toJSON().slice(0, 10);
}

export function withPlainDate<
  T extends { [P in TKeys]?: Date | undefined | null },
  TKeys extends keyof T,
>(object: T, dateKeysToConvert: TKeys[]): Simplify<WithPlainDate<T, TKeys>> {
  return replace({ object, dateKeysToConvert, converter: toPlainDate });
}

export function withPlainDateString<
  T extends { [P in TKeys]?: Date | Temporal.PlainDate | undefined | null },
  TKeys extends keyof T,
>(object: T, dateKeysToConvert: TKeys[]): WithPlainDateString<T, TKeys> {
  return replace({ object, dateKeysToConvert, converter: toPlainDateString });
}

export function toPlainDateTime(date: Date) {
  return Temporal.PlainDateTime.from(
    date.toISOString().split('Z')[0] as string,
  );
}

export function withPlainDateTime<
  T extends { [P in TKeys]?: Date | undefined | null },
  TKeys extends keyof T,
>(object: T, dateKeysToConvert: TKeys[]): WithPlainDateTime<T, TKeys> {
  return replace({ object, dateKeysToConvert, converter: toPlainDateTime });
}

export function toInstant(date: Date) {
  return toTemporalInstant.bind(date)();
}

export function withInstant<
  T extends { [P in TKeys]?: Date | undefined | null },
  TKeys extends keyof T,
>(object: T, dateKeysToConvert: TKeys[]): WithInstant<T, TKeys> {
  return replace({ object, dateKeysToConvert, converter: toInstant });
}

export function toJsDate(
  date:
    | Temporal.PlainDate
    | Temporal.PlainDateTime
    | Temporal.Instant
    | Temporal.ZonedDateTime,
): Date {
  if (date instanceof Temporal.PlainDateTime) {
    // prevent JS new Date to take machine local time zone
    date = UTC.getInstantFor(date);
  }
  if (date instanceof Temporal.ZonedDateTime) {
    date = date.toInstant();
  }

  return new Date(date.toJSON());
}

export function withJsDate<
  T extends {
    [P in TKeys]?:
      | Temporal.PlainDate
      | Temporal.PlainDateTime
      | Temporal.Instant
      | undefined
      | null;
  },
  TKeys extends keyof T,
>(object: T, dateKeysToConvert: TKeys[]): WithJsDate<T, TKeys> {
  return replace({ object, dateKeysToConvert, converter: toJsDate });
}

export function getTimeZoneId() {
  return Temporal.Now.zonedDateTimeISO().timeZoneId;
}

export function isPlainDate(
  date: Temporal.PlainDate | Temporal.Instant,
): date is Temporal.PlainDate {
  return date instanceof Temporal.PlainDate;
}

export function isPlainTime(
  date: Temporal.PlainTime | Temporal.Instant,
): date is Temporal.PlainTime {
  return date instanceof Temporal.PlainTime;
}

/**
 * Used when sorting `Array<Instant | PlainDate>`
 *
 * This will move a PlainDate at the very end of the day so it's the last action on a certain day in the timeline
 *
 * Ex:
 * ```tsx
 * const events: Array<Instant | PlainDate> = [...] // [[PlainDate Today], [Instant Today at 12:00]]
 * events.sort(
 *  (a,b) =>
 *  Temporal.Instant.Compare(
 *    toLastSecondIfPlainDate(a),
 *    toLastSecondIfPlainDate(b)
 *  )
 * )
 * console.log(events) // `[[Instant Today at 12:00], [PlainDate Today]]`
 * ```
 *
 * See {@link compareSortableEvent}
 */
export function toLastSecondIfPlainDate(
  date: Temporal.PlainDate | Temporal.Instant,
  tz: Parameters<Temporal.PlainDate['toZonedDateTime']>[0],
): Temporal.Instant {
  if (isPlainDate(date)) {
    const plainDateTime = date.toZonedDateTime(tz);
    const lastSecond = plainDateTime.with({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    return Temporal.Instant.from(lastSecond.toString());
  }

  return date;
}

type SortableEvent = {
  timestamp: Temporal.PlainDate | Temporal.Instant;
};

/**
 * Used to sort arrays of object that satisfying SortableEvent
 *
 * Temporal.PlainDate will be converted to an Instant at the last second of that date in the client time zone
 * Order may differ for different client time zone
 */
export function compareSortableEvent(a: SortableEvent, b: SortableEvent) {
  const tz = getTimeZoneId();
  return Temporal.Instant.compare(
    toLastSecondIfPlainDate(a.timestamp, tz),
    toLastSecondIfPlainDate(b.timestamp, tz),
  );
}

// Relative time formatting

export type TimeDescriptor =
  | 'scheduled'
  | 'estimated'
  | 'actual'
  | 'recommended';
export type TimePrecision = 'default' | 'days';

export type FormatRelativeTimeOpts<T> = {
  time: T;
  reference?: Temporal.ZonedDateTime;
  descriptor?: TimeDescriptor;
  precision?: TimePrecision;
};

function toDescribedDate(date: string, descriptor: TimeDescriptor) {
  const prefixes = {
    scheduled: 'Scheduled:',
    estimated: 'Estimated:',
    recommended: 'Recommended date:',
    actual: undefined,
  };

  const prefix = prefixes[descriptor];

  return prefix ? `${prefix} ${date}` : date;
}

function formatRelativePlainDate(
  opts: Omit<FormatRelativeTimeOpts<Temporal.PlainDate>, 'precision'>,
) {
  const {
    time,
    reference = Temporal.Now.zonedDateTimeISO(),
    descriptor = 'estimated',
  } = opts;

  const delta = time.until(reference, {
    largestUnit: 'days',
    smallestUnit: 'days',
    roundingMode: 'halfExpand',
  });

  if (delta.days === 1) {
    return `Yesterday`;
  }

  if (delta.days === 0) {
    return toDescribedDate(`Today`, descriptor);
  }

  const isTomorrow = delta.days === -1;
  const date = isTomorrow ? `Tomorrow` : toMonthDayString(time);
  return toDescribedDate(date, descriptor);
}

function formatRelativeInstant(opts: FormatRelativeTimeOpts<Temporal.Instant>) {
  const {
    time,
    reference = Temporal.Now.zonedDateTimeISO(),
    descriptor = 'estimated',
    precision = 'default',
  } = opts;

  const zonedTs = time.toZonedDateTimeISO(getTimeZoneId());

  const delta = zonedTs.until(reference, {
    largestUnit: 'day',
    smallestUnit: 'day',
    roundingMode: 'halfExpand',
  });

  const formatter =
    precision === 'default'
      ? toShortMonthDayAndTimeString
      : toShortMonthDayString;

  if (delta.days > 1) {
    return formatter(time);
  }

  const hour = time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

  if (delta.days === 1) {
    return `Yesterday at ${hour}`;
  }

  if (delta.days === 0) {
    return `Today at ${hour}`;
  }

  const date = delta.days === -1 ? `Tomorrow at ${hour}` : formatter(time);

  return toDescribedDate(date, descriptor);
}

type RelativeTimeLocation = 'start' | 'embedded';

export function formatRelativeTime(opts: {
  time: Temporal.Instant | Temporal.PlainDate;
  reference?: Temporal.ZonedDateTime;
  descriptor?: TimeDescriptor;
  precision?: TimePrecision;
  /**
   * Where the relative time is used in a sentence
   * @default 'start'
   */
  location?: RelativeTimeLocation;
}) {
  const {
    time,
    reference = Temporal.Now.zonedDateTimeISO(),
    descriptor = 'estimated',
    precision = 'default',
    location = 'start',
  } = opts;

  let value = isPlainDate(time)
    ? formatRelativePlainDate({ time, reference, descriptor })
    : formatRelativeInstant({ time, reference, descriptor, precision });

  value = value
    .replace('Yesterday', 'yesterday')
    .replace('Today', 'today')
    .replace('Tomorrow', 'tomorrow');

  // If this is used at the start of a sentence we want to make sure we didn't de-capitalize the first letter
  if (location === 'start') {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return value;
}

export function isPast(date: Temporal.PlainDate) {
  return Temporal.PlainDate.compare(date, Temporal.Now.plainDateISO()) < 0;
}

export const temporalPlainDateSchema = z.custom<Temporal.PlainDate>(
  (value) => isPlainDate(value as Temporal.PlainDate),
  'Not a Temporal.PlainDate',
);

export const temporalPlainTimeSchema = z.custom<Temporal.PlainTime>(
  (value) => isPlainTime(value as Temporal.PlainTime),
  'Not a Temporal.PlainTime',
);

export const temporalTimeZoneSchema = z.custom<Temporal.TimeZone>(
  (val) => val instanceof Temporal.TimeZone,
  'Must be a Temporal.TimeZone',
);

export const temporalInstantSchema = z.custom<Temporal.Instant>(
  (value) => value instanceof Temporal.Instant,
  'Not a Temporal.Instant',
);

export const isGreaterOrEqual = (
  a: Temporal.Duration,
  b: Temporal.Duration,
) => {
  return Temporal.Duration.compare(a, b) >= 0;
};

export function toDuration(interval: {
  unit: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
  quantity: number;
}): Temporal.Duration {
  return Temporal.Duration.from({
    [toDateUnit(interval.unit)]: interval.quantity,
  });
}
export function toDateUnit(
  unit: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR',
): Temporal.PluralUnit<Temporal.DateUnit> {
  switch (unit) {
    case 'DAY':
      return 'days';
    case 'WEEK':
      return 'weeks';
    case 'MONTH':
      return 'months';
    case 'YEAR':
      return 'years';
    default: {
      assertUnreachable(unit);
    }
  }
}
