import { useRouter } from 'next/router';
import type { Route } from 'nextjs-routes';

export function useBack(opts: { fallbackRoute: Route }) {
  const router = useRouter();

  const back = () => {
    if (window.history.length > 1) {
      router.back();
    } else {
      router.push(opts.fallbackRoute);
    }
  };

  return back;
}
