import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ArrowDownLeftIcon = createIcon('ArrowDownLeftIcon', [
  [
    'path',
    {
      d: 'M6.75 8.75V17.25H15.25',
      key: 'knr6ao',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M7 17L17.25 6.75',
      key: 't1636i',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ArrowDownLeftIcon;
