import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const OpenPaneIcon = createIcon('OpenPaneIcon', [
  [
    'path',
    {
      d: 'M14.25 4.75v14.5m-3-8.5L9.75 12l1.5 1.25m-4.5 6h10.5a2 2 0 0 0 2-2V6.75a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2Z',
      key: 'bz552s',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default OpenPaneIcon;
