import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const CheckIcon = createIcon('CheckIcon', [
  [
    'path',
    {
      d: 'M5.75 12.8665L8.33995 16.4138C9.15171 17.5256 10.8179 17.504 11.6006 16.3715L18.25 6.75',
      key: 'x0mzfs',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default CheckIcon;
