import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const EducationIcon = createIcon('EducationIcon', [
  [
    'path',
    {
      d: 'M4.75 10L12 5.75L19.2501 10L12 14.25L4.75 10Z',
      key: 'nu1irg',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12.5 10C12.5 10.2761 12.2761 10.5 12 10.5C11.7239 10.5 11.5 10.2761 11.5 10C11.5 9.72386 11.7239 9.5 12 9.5C12.2761 9.5 12.5 9.72386 12.5 10Z',
      key: '7i3fyh',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M6.75 11.5V16.25C6.75 16.25 8 18.25 12 18.25C16 18.25 17.25 16.25 17.25 16.25V11.5',
      key: '1ih8wn',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default EducationIcon;
