import { ComponentPropsWithoutRef } from 'react';
import { ErrorBoundary } from '~/modules/ui/components/error-boundary';
import { GlobalNavigation } from '~/modules/ui/components/global-navigation';
import { NoSSRSuspense } from '~/modules/ui/components/no-ssr-suspense';
import {
  AppView,
  AppViewColumn,
  AppViewContent,
  AppViewSidebar,
} from '~/modules/ui/layouts/app-view';
import { MotionProvider } from '~/modules/ui/primitives/motion-provider';

export function DefaultAppView({
  children,
  ...props
}: ComponentPropsWithoutRef<typeof AppViewColumn>) {
  return (
    <MotionProvider>
      <AppView>
        <AppViewContent>
          <AppViewSidebar>
            <GlobalNavigation />
          </AppViewSidebar>
          <AppViewColumn {...props}>
            <ErrorBoundary>
              <NoSSRSuspense>{children}</NoSSRSuspense>
            </ErrorBoundary>
          </AppViewColumn>
        </AppViewContent>
      </AppView>
    </MotionProvider>
  );
}
