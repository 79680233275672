import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const BookOpenIcon = createIcon('BookOpenIcon', [
  [
    'path',
    {
      d: 'M12 6.75L4.75 4.75V17.25L12 19.25',
      key: '1v0q8o',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 7V19',
      key: '12ruo6',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 6.75L19.25 4.75V17.25L12 19.25',
      key: '17d8s7',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default BookOpenIcon;
