import type { EffectCallback } from 'react';
import { useEffect, useRef } from 'react';

export const useOnMount = (callback: EffectCallback) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    isMounted.current = true;
    return callback();
  }, []);
};
