import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const BankIcon = createIcon('BankIcon', [
  [
    'path',
    {
      d: 'M18.25 11.5V19.25M5.75 19.25V11.5M9.75 19.25V11.5M14.25 19.25V11.5',
      key: 'jmqiov',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 4.75L19.25 11.25H4.75L12 4.75Z',
      key: '13a8v7',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M4.75 19.25H19.25',
      key: '14cvjk',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default BankIcon;
