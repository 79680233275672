import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const Layer3Icon = createIcon('Layer3Icon', [
  [
    'path',
    {
      d: 'M12 16.25L11.6207 16.897C11.8549 17.0343 12.1451 17.0343 12.3793 16.897L12 16.25ZM4.75 12L4.37071 11.353C4.14108 11.4876 4 11.7338 4 12C4 12.2662 4.14108 12.5124 4.37071 12.647L4.75 12ZM19.25 12L19.6293 12.647C19.8589 12.5124 20 12.2662 20 12C20 11.7338 19.8589 11.4876 19.6293 11.353L19.25 12ZM7.4619 11.2797C7.81924 11.0702 7.93911 10.6107 7.72963 10.2533C7.52016 9.896 7.06066 9.77613 6.70332 9.98561L7.4619 11.2797ZM17.2967 9.98561C16.9393 9.77613 16.4798 9.896 16.2704 10.2533C16.0609 10.6107 16.1808 11.0702 16.5381 11.2797L17.2967 9.98561ZM12.3793 15.603L5.12929 11.353L4.37071 12.647L11.6207 16.897L12.3793 15.603ZM12.3793 16.897L19.6293 12.647L18.8707 11.353L11.6207 15.603L12.3793 16.897ZM5.12929 12.647L7.4619 11.2797L6.70332 9.98561L4.37071 11.353L5.12929 12.647ZM19.6293 11.353L17.2967 9.98561L16.5381 11.2797L18.8707 12.647L19.6293 11.353Z',
      key: '14gynv',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
  [
    'path',
    {
      d: 'M12 19.25L11.6207 19.897C11.8549 20.0343 12.1451 20.0343 12.3793 19.897L12 19.25ZM4.75 15L4.37071 14.3529C4.14108 14.4875 4 14.7338 4 15C4 15.2661 4.14108 15.5124 4.37071 15.647L4.75 15ZM19.25 15L19.6293 15.647C19.8589 15.5124 20 15.2661 20 15C20 14.7338 19.8589 14.4875 19.6293 14.3529L19.25 15ZM7.4619 14.2796C7.81924 14.0701 7.93911 13.6106 7.72963 13.2533C7.52016 12.8959 7.06066 12.7761 6.70332 12.9855L7.4619 14.2796ZM17.2967 12.9855C16.9393 12.7761 16.4798 12.8959 16.2704 13.2533C16.0609 13.6106 16.1808 14.0701 16.5381 14.2796L17.2967 12.9855ZM12.3793 18.6029L5.12929 14.3529L4.37071 15.647L11.6207 19.897L12.3793 18.6029ZM12.3793 19.897L19.6293 15.647L18.8707 14.3529L11.6207 18.6029L12.3793 19.897ZM5.12929 15.647L7.4619 14.2796L6.70332 12.9855L4.37071 14.3529L5.12929 15.647ZM19.6293 14.3529L17.2967 12.9855L16.5381 14.2796L18.8707 15.647L19.6293 14.3529Z',
      key: '179u6x',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
  [
    'path',
    {
      d: 'M12 13.25L11.6207 13.897C11.8549 14.0343 12.1451 14.0343 12.3793 13.897L12 13.25ZM4.75 9L4.37071 8.35298C4.14108 8.48759 4 8.73382 4 9C4 9.26618 4.14108 9.51241 4.37071 9.64702L4.75 9ZM12 4.75L12.3793 4.10298C12.1451 3.96567 11.8549 3.96567 11.6207 4.10298L12 4.75ZM19.25 9L19.6293 9.64702C19.8589 9.51241 20 9.26618 20 9C20 8.73382 19.8589 8.48759 19.6293 8.35298L19.25 9ZM12.3793 12.603L5.12929 8.35298L4.37071 9.64702L11.6207 13.897L12.3793 12.603ZM5.12929 9.64702L12.3793 5.39702L11.6207 4.10298L4.37071 8.35298L5.12929 9.64702ZM12.3793 13.897L19.6293 9.64702L18.8707 8.35298L11.6207 12.603L12.3793 13.897ZM19.6293 8.35298L12.3793 4.10298L11.6207 5.39702L18.8707 9.64702L19.6293 8.35298Z',
      key: '1tli9u',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default Layer3Icon;
