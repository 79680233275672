'use client';

import { AnimatePresence, LazyMotion, domAnimation, m } from 'framer-motion';
import { PropsWithChildren } from 'react';

function MotionProvider({ children }: PropsWithChildren) {
  return <LazyMotion features={domAnimation}>{children}</LazyMotion>;
}

export { MotionProvider, m, AnimatePresence };
