import { type FC, createElement } from 'react';
import { Icon, type IconProps } from '~/modules/ui/primitives/icon/icon';

export const createIcon = (
  iconName: string,
  iconNode: [string, object][],
): FC<IconProps> => {
  const Component = (props: IconProps) => (
    <Icon {...props}>
      {...iconNode.map(([tag, attrs]) => createElement(tag, attrs))}
    </Icon>
  );
  Component.displayName = `${iconName}`;
  return Component;
};
