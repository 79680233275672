import type { Simplify } from 'type-fest';

/**
 * Turns
 * { Id: 3, NameThing: potato}
 * into
 * { id: 3, nameThing: potato}
 *
 * Shamelessly taken from https://stackoverflow.com/questions/12539574/whats-the-best-way-most-efficient-to-turn-all-the-keys-of-an-object-to-lower
 */
// biome-ignore lint/suspicious/noExplicitAny: ok
export function lowercaseFirstLetterOfObjectKeys(input: any): any {
  if (input === null || input === undefined) {
    return input;
  }
  if (typeof input !== 'object') {
    return input;
  }
  if (Array.isArray(input)) {
    return input.map(lowercaseFirstLetterOfObjectKeys);
  }
  // biome-ignore lint/suspicious/noExplicitAny: ok
  return Object.keys(input).reduce((newObj: any, key) => {
    const value = input[key];
    const newValue =
      typeof value === 'object' && value !== null
        ? lowercaseFirstLetterOfObjectKeys(value)
        : value;
    const firstChar = key[0];
    const newKey = firstChar ? firstChar.toLowerCase() + key.slice(1) : key;
    newObj[newKey] = newValue;
    return newObj;
  }, {});
}

type PropertyType =
  | 'undefined'
  | 'object'
  | 'boolean'
  | 'number'
  | 'bigint'
  | 'string'
  | 'symbol'
  | 'function';

/**
 * Verify if a property exists on an object and it's of a certain type.
 */
// biome-ignore lint/suspicious/noExplicitAny: ok
export function hasPropertyOfType<T extends { [key: string]: any }>(opts: {
  obj: T;
  property: string;
  type: PropertyType;
}) {
  const { obj, property, type } = opts;

  if (!obj) {
    return false;
  }

  return property in obj && typeof obj[property] === type;
}

/**
 * Verify if the input is an object.
 */
export function isObject(value: unknown): value is Record<string, unknown> {
  return value !== null && !Array.isArray(value) && typeof value === 'object';
}

/**
 * Used JSON.stringify limitation with BigInt
 * It fixes `JSON.stringify() doesn't know how to serialize a BigInt`
 */
export function toObject(value: unknown) {
  if (typeof value === 'undefined') {
    return undefined;
  }
  return JSON.parse(
    JSON.stringify(value, (_, value) =>
      typeof value === 'bigint' ? value.toString() : value,
    ),
  );
}

type NonNull<T> = Exclude<T, null>;

export type NonNullRecord<T> = { [P in keyof T]: NonNull<T[P]> };

/**
 * nonNullRecord is a bit of a hack to get around the fact that the `vendor`
 * fields are nullable and our schema doesn't expect `null` values everywhere.
 * This makes sure the schema doesn't validate against `null`. This feels like a
 * code smell and should probably be deprecated and resolved.
 */

// biome-ignore lint/suspicious/noExplicitAny: ok
export function nonNullRecord<T extends Record<string, any | null | undefined>>(
  obj: T,
) {
  const result: Record<string, symbol | undefined> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null) {
      result[key] = value;
    }
  }
  return result as Simplify<NonNullRecord<T>>;
}

/**
 * Like `Object.keys` but with a type-safe return value.
 */
export function objectKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}
/**
 * Like `Object.entries` but with a type-safe return value.
 */
export function objectEntries<
  T extends { [Key in K]: string | number | symbol },
  K extends keyof T,
>(obj: T): Array<[K, T[K]]> {
  return Object.entries(obj) as Array<[K, T[K]]>;
}
