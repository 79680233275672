import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const InformationIcon = createIcon('InformationIcon', [
  [
    'path',
    {
      d: 'M12 13V15',
      key: '58wmwp',
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'circle',
    {
      cx: '12',
      cy: '9',
      r: '1',
      strokeWidth: 0,
      key: 'a9soac',
      fill: 'currentcolor',
    },
  ],
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '7.25',
      strokeWidth: '1.5',
      key: 'okggfd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default InformationIcon;
