import type {
  AnyPermissionDefinition,
  inferPermissionScope,
} from './createPermissions';
import type { AbilityRecord } from './createRoleAbilities';
import type { MakeEmptyObjectsOptional, OmitNever } from './internals/types';

// biome-ignore lint/suspicious/noExplicitAny: ok
type Context = Record<string, any>;

type ConditionFn<TContext extends Context, TData> = (opts: {
  ctx: TContext;
  data: TData;
}) => boolean;

type RuntimeConditionRecord<
  TDef extends AnyPermissionDefinition,
  TContext extends Context,
> = MakeEmptyObjectsOptional<{
  [TEntity in keyof TDef]: OmitNever<{
    [Action in keyof TDef[TEntity]]: TDef[TEntity][Action] extends string[]
      ? {
          // TODO ensure that these condition fns are the same for each actions?
          [TAction in TDef[TEntity][Action][number]]: ConditionFn<
            TContext,
            // biome-ignore lint/suspicious/noExplicitAny: ok
            any
          >;
        }
      : never;
  }>;
}>;

type ValueOf<T> = T[keyof T];

type inferRuleFnData<
  // biome-ignore lint/suspicious/noExplicitAny: ok
  TRuntimeConditions extends RuntimeConditionRecord<any, any>,
  TEntity extends keyof TRuntimeConditions,
  TAction extends string,
> = TRuntimeConditions[TEntity] extends Record<TAction, infer TRule>
  ? // biome-ignore lint/suspicious/noExplicitAny: ok
    ValueOf<TRule> extends ConditionFn<any, infer TData>
    ? unknown extends TData
      ? never
      : TData
    : never
  : never;

type CanFn<
  TDef extends AnyPermissionDefinition,
  // biome-ignore lint/suspicious/noExplicitAny: ok
  TConditionRecord extends RuntimeConditionRecord<any, any>,
> = <
  TEntity extends keyof TDef & string,
  TAction extends keyof TDef[TEntity] & string,
>(
  scope: inferPermissionScope<TDef> | `${TEntity}.${TAction}`,
  ...data: inferRuleFnData<TConditionRecord, TEntity, TAction> extends never
    ? []
    : [inferRuleFnData<TConditionRecord, TEntity, TAction>]
) => boolean;

type RuntimeConditions<
  TDef extends AnyPermissionDefinition,
  TContext extends Context,
  // biome-ignore lint/suspicious/noExplicitAny: ok
  TConditionRecord extends RuntimeConditionRecord<any, any>,
> = {
  resolve(opts: { ctx: TContext; abilities?: AbilityRecord<TDef> }): {
    can: CanFn<TDef, TConditionRecord>;
    cannot: CanFn<TDef, TConditionRecord>;
  };
};

// biome-ignore lint/suspicious/noExplicitAny: ok
export type AnyRuntimeConditions = RuntimeConditions<any, any, any>;

export function createRuntimeConditions<TDef extends AnyPermissionDefinition>(
  def: TDef,
) {
  return {
    context<TContext extends Context>() {
      return {
        condition<TData>() {
          return (fn: ConditionFn<TContext, TData>) => fn;
        },
        create<TConditionRecord extends RuntimeConditionRecord<TDef, TContext>>(
          record: TConditionRecord,
        ): RuntimeConditions<TDef, TContext, TConditionRecord> {
          return {
            resolve(opts) {
              const can: CanFn<TDef, TConditionRecord> = (scope, ...data) => {
                const [entity, action] = scope.split('.') as [string, string];

                if (!opts.abilities) {
                  return false;
                }
                const viewerAbility = opts.abilities[entity]?.[action];

                const isBooleanCondition = def[entity]?.[action] === Boolean;
                if (isBooleanCondition || !viewerAbility) {
                  if (
                    typeof viewerAbility !== 'boolean' &&
                    typeof viewerAbility !== 'undefined'
                  ) {
                    // This is guaranteed by the types already, but people can mess stuff up
                    throw new Error(
                      `Invalid ability setup on ${entity}.${action}`,
                    );
                  }
                  return Boolean(viewerAbility);
                }
                // biome-ignore lint/suspicious/noExplicitAny: ok
                const fn = (record as any)[entity][action][
                  viewerAbility
                  // biome-ignore lint/suspicious/noExplicitAny: ok
                ] as ConditionFn<TContext, any>;

                // biome-ignore lint/suspicious/noExplicitAny: ok
                return fn({ ctx: opts.ctx, data: data[0] as any });
              };
              return {
                can,
                cannot: (scope, ...data) => !can(scope, ...data),
              };
            },
          };
        },
      };
    },
  };
}
