import { Temporal } from '@js-temporal/polyfill';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { toPlainDateString } from '~/utils/temporal';

/**
 * Safely rounds a number with set number of decimal places.
 * JS doesn't make this easy.
 * @link https://stackoverflow.com/a/32178833
 */
export function round(value: number | bigint, decimalPlaces = 0) {
  return Number(
    Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces,
  );
}

/**
 * Safely rounds a number UP with a set number of decimal places.
 * See alse {@link round}
 * @link https://stackoverflow.com/a/32178833
 */
export function roundUp(value: number | bigint, decimalPLaces = 0) {
  return Number(
    Math.ceil(parseFloat(value + 'e' + decimalPLaces)) + 'e-' + decimalPLaces,
  );
}

/**
 * @param series an array of numbers
 * @returns the average
 */
export function average(series: number[]) {
  const sum = series.reduce((p, c) => p + c, 0);
  return sum / series.length;
}

/**
 * i.e.  Jul 6
 */
export function toShortMonthDayString(
  date: Temporal.PlainDate | Temporal.Instant,
): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
  });
}

/**
 * i.e. July 6
 */
export function toMonthDayString(date: Temporal.PlainDate): string {
  return date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
  });
}

/**
 * i.e. July 2022
 */
export function toMonthYearString(date: Temporal.PlainDate | Date): string {
  return date.toLocaleString('en-US', {
    month: 'long',
    year: 'numeric',
  });
}

/**
 * If the year is the current year, only the month is returned.
 * i.e. July
 * i.e. July 2022
 */
export function toMonthString(date: Temporal.PlainYearMonth): string {
  const isSameYear = date.year === Temporal.Now.plainDateISO().year;
  return date.toLocaleString('en-US', {
    month: 'long',
    year: isSameYear ? undefined : 'numeric',
    calendar: 'iso8601',
  });
}

/**
 * i.e. July 6, 2022
 */
export function toMonthDayYearString(date: Temporal.PlainDate | Date): string {
  return date.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

/**
 * i.e. Aug 11, 2:58 PM
 */
export function toShortMonthDayAndTimeString(
  date: Temporal.Instant | Date,
): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

/**
 * i.e. Aug 11, 2022
 */
export function toShortDate(
  date: Temporal.PlainDate | Temporal.Instant | Date,
): string {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

export function dateRangeToPlainDateString(from?: Date, to?: Date) {
  return {
    from: toPlainDateString(from ?? new Date()),
    to: toPlainDateString(to ?? from ?? new Date()),
  };
}

/*
 * i.e. Mar 11 – May 11, 2024
 */
export function toShortDateRange(
  startDate: Temporal.PlainDate | Date,
  endDate: Temporal.PlainDate | Date,
): string {
  return new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).formatRange(new Date(startDate.toString()), new Date(endDate.toString()));
}

/**
 * i.e. S M T W T F S
 */
export function toShortWeekDay(date: Temporal.PlainDate | Date): string {
  return date
    .toLocaleString('en-US', {
      weekday: 'short',
    })
    .slice(0, 1);
}
// Not used, also, to preciseDate is not a concept that works with a PlainDate
// export function toPreciseDate(date: Temporal.PlainDate) {
//   return date.toLocaleString('en-US', {
//     day: '2-digit',
//     month: 'long',
//     hour: 'numeric',
//     minute: 'numeric',
//   });
// }

export function toMaskedBankingNumber(value: string): string {
  const show = 4;
  if (value.length < show) {
    return toMaskedString('', value.length);
  }
  return toMaskedString(value.slice(show * -1), value.length - show);
}

export function toMaskAccountNumber(v: string): string {
  const last4 = v.slice(-4);
  return last4;
}

export function toMaskedString(v: string | null, places = 4): string {
  const char = `\u2022`;
  if (v === null) {
    return '';
  }
  const mask = new Array(places).fill(char).join('');
  return `${mask}${v}`;
}

export function toMaskedPhone(v: string | null): string {
  if (v === null) {
    return '';
  }
  const length = v.length;
  const last4 = v.slice(-4);
  return toMaskedString(last4, length - 4);
}

export function toPercentString(v: number): string {
  return `${Math.round(v * 10000) / 100}%`;
}

export function toUnixTime(d: Date): string {
  return toUnixEpoch(d).toString();
}

// Unix epoch in seconds
export function toUnixEpoch(d: Date | Temporal.Instant): number {
  if (d instanceof Temporal.Instant) {
    return d.epochSeconds;
  }
  return Math.floor(d.getTime() / 1000);
}

/**
 * Formats a phone number to an international format.
 * Defaults to US format if no country code is provided.
 *
 * @example
 * toInternationalPhoneNumber('5555555555') // => '+1 555 555 5555'
 * toInternationalPhoneNumber('+46555555555') // => '+46 555 555 555'
 */
export function toInternationalPhoneNumber(
  phoneNumber: string | null | undefined,
): string {
  if (!phoneNumber) {
    return '';
  }

  return (
    parsePhoneNumberFromString(phoneNumber, 'US')?.formatInternational() ??
    phoneNumber
  );
}

/**
 * Create a date `YYYY-MM-DD` string from a `Date`
 */
export function dateToPlainDateString(date: Date | Temporal.PlainDate) {
  return date.toJSON().slice(0, 10);
}
