import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ChevronRightIcon = createIcon('ChevronRightIcon', [
  [
    'path',
    {
      d: 'M10.75 8.75L14.25 12L10.75 15.25',
      key: '1i4t7g',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ChevronRightIcon;
