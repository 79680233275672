import { useCallback, useRef } from 'react';

/**
 * Creates memoized event callback that always references the latest version of the callback.
 * @example
 * ```tsx
 * function MyComponent(props: {
 *   onStateChange: (state: string) => void;
 * }) {
 *   const [state, setState] = useState('initial');
 *
 *   const onStateChange = useEventCallback(props.onStateChange);
 *   useEffect(() => {
 *     onStateChange(state);
 *   }, [state, onStateChange]);
 *
 *   // [...]
 * }
 * ```
 * @see https://usehooks-ts.com/react-hook/use-event-callback
 */
export function useEventCallback<TArgs extends unknown[], TReturn>(
  fn: (...args: TArgs) => TReturn,
): (...args: TArgs) => TReturn;
export function useEventCallback<TArgs extends unknown[], TReturn>(
  fn: ((...args: TArgs) => TReturn) | undefined,
): (...args: TArgs) => TReturn | undefined;
export function useEventCallback(
  fn: ((...args: unknown[]) => unknown) | undefined,
) {
  const ref = useRef(fn);

  ref.current = fn;

  return useCallback((...args: unknown[]) => {
    return ref.current?.(...args);
  }, []);
}
