/**
 * Mini Library for safe floating-point math. Precision and decimal places are
 * set to 35 and 7 respectively to match the parameters of our NUMERIC(35, 7)
 * fields in postgres.
 *
 * Nobody likes it but here we are (jk I secretly love it)
 */
import { Decimal } from 'decimal.js';
import { z } from 'zod';

/**
 * Total number of significant digits
 */
export const PRECISION = 35;
/**
 * Maximum number of decimal places when obtaining a number/string
 * representation.
 */
export const DECIMAL_PLACES = 7;
/**
 * Rounding mode for Decimal.js
 */

export const ROUNDING = Decimal.ROUND_HALF_UP;

const _Decimal = Decimal.clone({
  precision: PRECISION,
  rounding: ROUNDING,
});

/**
 * A branded type for representing a PreciseDecimal as a string.
 *
 * @warning Casting to this type outside of the PreciseDecimal class is UNSAFE.
 * Use `PreciseDecimal.of(val).serialize()` instead. Or else.
 */
export type SerializedPreciseDecimal = string & {
  __brand: '__brand_SerializedPreciseDecimal';
};

/**
 * Accepted types for Decimal constructor. Mimics Decimal.Value
 */
type Value = PreciseDecimal | SerializedPreciseDecimal | Decimal.Value;

/**
 * A thin wrapper on Decimal.js to ensure consistent precision and rounding for
 * financial calculations involving floating point numbers.
 *
 * Intermediate calculations (e.g. chaining multiplication) are done with 35
 * significant digits, with no cap on decimal places. Accessing the final
 * decimal result rounds to 7 decimal places, whether through `toDecimal` or
 * `serialize`. Converting to cents is done by multiplying by 100 and rounding
 * to 0 decimal places.
 *
 * Note: We `ROUND_HALF_UP` for consistency with our existing accounting
 * integrations. Nick would strongly prefer to ROUND_HALF_EVEN, but this would
 * mismatch with integrations.
 */
export class PreciseDecimal {
  private readonly decimal: Decimal;

  public static from(val: Value): PreciseDecimal {
    return new PreciseDecimal(val);
  }

  public static fromCents(val: bigint): PreciseDecimal {
    return new PreciseDecimal(new _Decimal(val.toString()).dividedBy(100));
  }

  private constructor(val: Value) {
    this.decimal =
      val instanceof PreciseDecimal ? val.toDecimal() : new _Decimal(val);
  }

  public times(val: Value): PreciseDecimal {
    const other = PreciseDecimal.from(val).toDecimal();
    return new PreciseDecimal(this.decimal.times(other));
  }

  public plus(val: Value): PreciseDecimal {
    const other = PreciseDecimal.from(val).toDecimal();
    return new PreciseDecimal(this.decimal.plus(other));
  }

  public toDecimal(): Decimal {
    return this.decimal.toDP(DECIMAL_PLACES);
  }

  /** Multiply by 100, round to zero decimal places, convert to BigInt */
  public toCents(): bigint {
    return BigInt(this.decimal.times('100').toFixed(0));
  }

  /** Convert to string with exactly 7 decimal places, with padded zeros  */
  public serialize(): SerializedPreciseDecimal {
    return this.decimal.toFixed(DECIMAL_PLACES) as SerializedPreciseDecimal;
  }

  public equals(other: Value): boolean {
    return this.decimal.eq(PreciseDecimal.from(other).toDecimal());
  }
}

export const serializedPreciseDecimalSchema = () =>
  z
    .custom<Value>(
      (val) =>
        val instanceof PreciseDecimal ||
        Decimal.isDecimal(val) ||
        typeof val === 'string' ||
        typeof val === 'number',
    )
    .transform((val) => PreciseDecimal.from(val).serialize());

export const preciseDecimalSchema = () =>
  z
    .custom<Value>(
      (val) =>
        val instanceof PreciseDecimal ||
        Decimal.isDecimal(val) ||
        typeof val === 'string' ||
        typeof val === 'number',
    )
    .transform((val) => PreciseDecimal.from(val));
