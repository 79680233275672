import type { PostHogConfig } from 'posthog-js';
import {
  PostHogProvider as BasePostHogProvider,
  usePostHog as useBasePostHog,
} from 'posthog-js/react';
import { usePublicConfig } from '~/hooks/usePublicConfig';

const options: Partial<PostHogConfig> = {
  api_host: '/ingest',
  enable_recording_console_log: true,
};
export const PostHogProvider = (props: { children: React.ReactNode }) => {
  const publicConfig = usePublicConfig();
  const key = publicConfig.POSTHOG_API_KEY;

  if (!key) {
    return props.children;
  }

  return (
    <BasePostHogProvider apiKey={key} options={options}>
      {props.children}
    </BasePostHogProvider>
  );
};

export const usePostHog = () => {
  const posthog = useBasePostHog();
  // posthog has a bug and this property should be more 'public'. until they fix
  // their bug on their end we just turn the posthog client into an undefined or
  // client response.
  if (posthog.__loaded) {
    return posthog;
  }
  return undefined;
};
