import { cn } from '~/modules/ui/cva';

export const NotBankDisclaimer = () => {
  return (
    <p
      className={cn('t2-text-body t2-text-foreground-secondary t2-text-pretty')}
    >
      Tola Inc. is not a FDIC-insured institution. Banking services provided by
      Patriot Bank, N.A., Member FDIC.
    </p>
  );
};
