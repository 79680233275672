import { Slot } from '@radix-ui/react-slot';
import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef } from 'react';
import { type VariantProps, cn, cva } from '~/modules/ui/cva';

const stackVariants = cva({
  base: ['t2-flex'],
  variants: {
    direction: {
      vertical: 't2-flex-col',
      horizontal: 't2-flex-row',
      'horizontal-reverse': 't2-flex-row-reverse',
      'vertical-reverse': 't2-flex-col-reverse',
    },
    wrap: {
      wrap: 't2-flex-wrap',
      nowrap: 't2-flex-nowrap',
      reverse: 't2-flex-wrap-reverse',
    },
    alignItems: {
      start: 't2-items-start',
      end: 't2-items-end',
      center: 't2-items-center',
      stretch: 't2-items-stretch',
      baseline: 't2-items-baseline',
    },
    alignContent: {
      start: 't2-content-start',
      end: 't2-content-end',
      baseline: 't2-content-baseline',
      center: 't2-content-center',
      stretch: 't2-content-stretch',
      around: 't2-content-around',
      between: 't2-content-between',
      evenly: 't2-content-evenly',
    },
    justifyContent: {
      start: 't2-justify-start',
      end: 't2-justify-end',
      center: 't2-justify-center',
      around: 't2-justify-around',
      between: 't2-justify-between',
      evenly: 't2-justify-evenly',
    },
    gap: {
      '0': 't2-gap-0',
      '0.5': 't2-gap-0.5',
      '1': 't2-gap-1',
      '1.5': 't2-gap-1.5',
      '2': 't2-gap-2',
      '2.5': 't2-gap-2.5',
      '3': 't2-gap-3',
      '4': 't2-gap-4',
      '5': 't2-gap-5',
      '6': 't2-gap-6',
      '7': 't2-gap-7',
      '8': 't2-gap-8',
      '9': 't2-gap-9',
      '10': 't2-gap-10',
      '12': 't2-gap-12',
      '16': 't2-gap-16',
      '20': 't2-gap-20',
    },
  },
});

type StackVariants = VariantProps<typeof stackVariants>;

export interface StackProps
  extends ComponentPropsWithoutRef<'div'>,
    StackVariants {
  asChild?: boolean;
}

const Stack = forwardRef<HTMLDivElement, StackProps>(function StackWithRef(
  {
    alignContent,
    alignItems,
    asChild,
    className,
    direction,
    gap,
    justifyContent,
    wrap,
    ...props
  },
  forwardedRef,
) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={cn(
        stackVariants({
          direction,
          wrap,
          alignItems,
          alignContent,
          justifyContent,
          gap,
        }),
        className,
      )}
      ref={forwardedRef}
      {...props}
    />
  );
});

const HStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  function HStackWithRef(props, forwardedRef) {
    return <Stack direction="horizontal" ref={forwardedRef} {...props} />;
  },
);

const VStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  function VStackWithRef(props, forwardedRef) {
    return <Stack direction="vertical" ref={forwardedRef} {...props} />;
  },
);

export { Stack, HStack, VStack };
