'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect } from 'react';
import { PublicConfig } from '~/app/_utils/publicConfig';
import { usePublicConfig } from '~/hooks/usePublicConfig';

export function anonymizeUrl(url: string) {
  // By having this filter first, we don't risk anonymizing the /org/[:slug]/pay route
  if (url.includes('/org/')) {
    return url.replace(/\/org\/[\w-]+/, '/org/[:slug]');
  }

  return url.replace(/\/pay\/[\w-]+/, '/pay/[:slug]');
}

export function useInitDatadog(publicConfig: PublicConfig) {
  useEffect(() => {
    if (publicConfig.APP_ENV !== 'production') {
      return;
    }
    datadogRum.init({
      applicationId: publicConfig.DATADOG_APPLICATION_ID,
      clientToken: publicConfig.DATADOG_CLIENT_TOKEN,
      version: publicConfig.GIT_COMMIT,
      site: 'datadoghq.com',
      service: 'frontend',
      env: publicConfig.APP_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap'],
      beforeSend: (event) => {
        // Anonymize the org slug
        if (event.type === 'view') {
          event.view.url = anonymizeUrl(event.view.url);
        }
      },
    });

    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
      clientToken: publicConfig.DATADOG_CLIENT_TOKEN,
      version: publicConfig.GIT_COMMIT,
      site: 'datadoghq.com',
      service: 'frontend',
      env: publicConfig.APP_ENV,
      sessionSampleRate: 100,

      // Sending console.error to Datadog
      forwardErrorsToLogs: true,
      // not sending any console.log to Datadog
      forwardConsoleLogs: [],
    });
  }, [
    publicConfig.APP_ENV,
    publicConfig.DATADOG_APPLICATION_ID,
    publicConfig.DATADOG_CLIENT_TOKEN,
    publicConfig.GIT_COMMIT,
  ]);
}

type DatadogUser = {
  id: string;
  name: string;
  email: string;
  organization?: {
    id: string;
    slug: string;
  };
};
export function useSetDatadogUser() {
  const publicConfig = usePublicConfig();

  return useCallback(
    (user: DatadogUser) => {
      if (publicConfig.APP_ENV !== 'production') {
        return;
      }

      datadogRum.setUser(user);
      datadogLogs.setUser(user);
    },
    [publicConfig.APP_ENV],
  );
}
