import { signOut } from 'next-auth/react';
import { useState } from 'react';
import { useIntercom } from 'react-use-intercom';

export function useLogout() {
  const intercom = useIntercom();
  const [loading, setLoading] = useState(false);

  const logout = async (opts?: { callbackUrl?: string }) => {
    setLoading(true);
    try {
      intercom.hardShutdown();
      await signOut({
        callbackUrl:
          opts?.callbackUrl ??
          window.location.pathname + window.location.search,
      });
    } catch (cause) {
      setLoading(false);
      throw cause;
    }
  };

  return {
    loading,
    logout,
  };
}
