'use client';

import * as AvatarPrimitive from '@radix-ui/react-avatar';
import * as React from 'react';
import { cn, cva } from '~/modules/ui/cva';

const avatarVariants = cva({
  base: [
    't2-relative t2-flex t2-shrink-0 t2-overflow-hidden t2-rounded-full',
    'after:t2-pointer-events-none after:t2-absolute after:t2-inset-0 after:t2-z-10 after:t2-rounded-full after:t2-border after:t2-opacity-20',
  ],
  variants: {
    size: {
      sm: 't2-size-5',
      md: 't2-size-6',
      lg: 't2-size-8',
      xl: 't2-size-10',
    },
  },
});

interface AvatarProps
  extends React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> {
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

const Avatar = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, size = 'md', ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(avatarVariants({ size }), className)}
    {...props}
  />
));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn('t2-aspect-square t2-size-full', className)}
    {...props}
  />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn(
      't2-bg-surface-muted t2-flex t2-leading-none t2-size-full t2-select-none t2-items-center t2-justify-center t2-rounded-full t2-text-caption',
      className,
    )}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { Avatar, AvatarFallback, AvatarImage };
