import type { ComponentPropsWithoutRef } from 'react';
import { focusRingClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { HStack, VStack } from '~/modules/ui/primitives/stack';
import { PaymentsByTola } from '~/modules/ui/primitives/wordmark';

export function AppView({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <div className="t2-bg-background">
      <VStack
        className={cn(
          't2-bg-background t2-relative t2-min-h-screen t2-w-full t2-p-4 md:t2-p-6 t2-gap-4 md:t2-gap-6',
          className,
        )}
        {...props}
      >
        {children}
      </VStack>
    </div>
  );
}

export function AppViewContent({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <HStack
      className={cn(
        't2-grow t2-gap-x-4 md:t2-gap-x-6 lg:t2-gap-x-10 t2-transition-[gap]',
        className,
      )}
      {...props}
    >
      {children}
    </HStack>
  );
}

export function AppViewColumn({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof VStack>) {
  return (
    <VStack
      gap="6"
      className={cn(
        't2-relative t2-flex-auto t2-w-1/2 t2-grow t2-pb-[10vh]',
        className,
      )}
      {...props}
    >
      {children}
    </VStack>
  );
}

export function AppViewColumnActions({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<typeof HStack>) {
  return (
    <HStack
      justifyContent="end"
      className={cn(
        't2-sticky t2-top-4 md:t2-top-6 t2-z-30 t2-pointer-events-none [&>*]:t2-pointer-events-auto',
        className,
      )}
      {...props}
    >
      {children}
    </HStack>
  );
}

export function AppViewSidebar({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        't2-fixed t2-shrink-0 t2-flex t2-flex-col t2-items-start t2-z-30',
        'md:t2-relative md:t2-max-w-64',
        className,
      )}
      {...props}
    >
      <div className="t2-sticky t2-top-4 md:t2-top-6 t2-max-w-full">
        {children}
      </div>
    </div>
  );
}

export function AppViewFooter({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        't2-flex t2-flex-col-reverse t2-items-start t2-gap-y-5 t2-py-2',
        'sm:t2-flex-row sm:t2-items-center sm:t2-justify-between',
        className,
      )}
      {...props}
    >
      <PaymentsByTola />
      <HStack alignItems="center" gap="6" className="sm:t2-gap-8">
        <AppViewFooterLink href="https://usetola.com/legal/terms-of-service">
          Terms of Service
        </AppViewFooterLink>
        <AppViewFooterLink href="https://usetola.com/legal/privacy-policy">
          Privacy policy
        </AppViewFooterLink>
      </HStack>
    </div>
  );
}

export function AppViewFooterLink({
  className,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <a
      className={cn(
        focusRingClassNames,
        'focus-visible:t2-rounded-sm focus-visible:t2-ring-offset-background',
        't2-text-foreground-secondary t2-leading-4 t2-decoration-1 t2-underline-offset-2 hover:t2-underline',
        className,
      )}
      {...props}
    />
  );
}
