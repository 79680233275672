import type { ComponentPropsWithoutRef } from 'react';
import { type VariantProps, cn } from '~/modules/ui/cva';
import { cva } from '~/utils/css-utils';

const iconVariants = cva({
  base: 't2-inline-block t2-shrink-0 t2-fill-none t2-stroke-current t2-stroke-[1.25]',
  variants: {
    size: {
      sm: 't2-size-4',
      md: 't2-size-6',
      lg: 't2-size-8',
    },
  },
});

type IconVariants = VariantProps<typeof iconVariants>;

export type IconProps = Omit<
  ComponentPropsWithoutRef<'svg'>,
  'width' | 'height' | 'viewBox'
> &
  IconVariants;

export function Icon({
  children,
  className,
  size = 'md',
  ...props
}: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(iconVariants({ size }), className)}
      {...props}
      aria-hidden
    >
      {children}
    </svg>
  );
}

export type Icon = typeof Icon;
