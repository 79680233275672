'use client';

import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import { LinkProps } from 'next/link';
import { Route } from 'nextjs-routes';
import * as React from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useIntercom } from 'react-use-intercom';
import { useDebouncedCallback } from 'use-debounce';
import { useOnEscape } from '~/hooks/useOnEscape';
import { Tooltip } from '~/modules/ui/components/tooltip';
import { VariantProps, cn, cva } from '~/modules/ui/cva';
import type {
  ButtonLike,
  SubmitButtonLike,
} from '~/modules/ui/primitives/button';
import {
  Button,
  LinkButton,
  SubmitButton,
} from '~/modules/ui/primitives/button';
import {
  ArrowLeftIcon,
  CloseIcon,
  MessageIcon,
  SearchIcon,
} from '~/modules/ui/primitives/icon';
import { Input } from '~/modules/ui/primitives/input';
import { useBack } from '~/modules/ui/use-back';
import { isString } from '~/utils/utility';

const toolbarVariants = cva({
  base: 't2-p-2 t2-inline-flex',
  variants: {
    intent: {
      primary: 't2-bg-surface',
      secondary: 't2-bg-surface-muted',
      ghost: 't2-bg-transparent',
    },
    orientation: {
      vertical: 't2-flex-col t2-rounded-2xl t2-gap-y-0.5 t2-items-start',
      horizontal: 't2-flex-row t2-rounded-full t2-gap-x-0.5 t2-items-center',
    },
  },
  defaultVariants: {
    intent: 'secondary',
    orientation: 'horizontal',
  },
});

type ToolbarVariants = VariantProps<typeof toolbarVariants>;

type ToolbarProps = React.ComponentPropsWithoutRef<
  typeof ToolbarPrimitive.Root
> &
  ToolbarVariants;

const Toolbar = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Root>,
  ToolbarProps
>(
  (
    {
      className,
      children,
      intent = 'secondary',
      orientation = 'horizontal',
      ...props
    },
    ref,
  ) => (
    <ToolbarPrimitive.Root
      ref={ref}
      className={cn(
        toolbarVariants({ intent, orientation }),
        't2-group',
        className,
      )}
      orientation={orientation}
      data-intent={intent}
      {...props}
    >
      {children}
    </ToolbarPrimitive.Root>
  ),
);
Toolbar.displayName = ToolbarPrimitive.Root.displayName;

const ToolbarToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.ToggleGroup>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.ToggleGroup>
>(({ className, ...props }, ref) => (
  <ToolbarPrimitive.ToggleGroup
    ref={ref}
    className={cn('t2-flex t2-items-center t2-gap-x-0.5', className)}
    {...props}
  />
));
ToolbarToggleGroup.displayName = ToolbarPrimitive.ToggleGroup.displayName;

type ToolbarToggleItemProps = ButtonLike<
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.ToggleItem>
>;

const ToolbarToggleItem = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.ToggleItem>,
  ToolbarToggleItemProps
>(({ className, children, intent = 'ghost', ...props }, ref) => (
  <ToolbarPrimitive.ToggleItem ref={ref} {...props} asChild>
    <Button
      className={cn(
        'focus-visible:z-10',
        'data-[state=on]:t2-bg-surface data-[state=on]:t2-text-foreground data-[state=on]:z-10',
        className,
      )}
      intent={intent}
    >
      {children}
    </Button>
  </ToolbarPrimitive.ToggleItem>
));
ToolbarToggleItem.displayName = ToolbarPrimitive.ToggleItem.displayName;

const ToolbarSeparator = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <ToolbarPrimitive.Separator
    ref={ref}
    className={cn(
      't2-bg-border/30 t2-mx-2',
      'data-[orientation=vertical]:t2-w-px data-[orientation=vertical]:t2-h-6',
      'data-[orientation=horizontal]:t2-my-2 data-[orientation=horizontal]:t2-mx-2.5 data-[orientation=horizontal]:t2-h-px data-[orientation=horizontal]:t2-self-stretch',
      className,
    )}
    {...props}
  />
));
ToolbarSeparator.displayName = ToolbarPrimitive.Separator.displayName;

const toolbarLinkOrButtonClassNames = [
  'focus-visible:t2-ring-offset-surface-muted',
  'data-[state=open]:t2-text-foreground-secondary/50 data-[state=open]:z-10',
  // When a toolbar is transparent, the secondary button colours fade into the background.
  // These class names ensure that when the intent is 'ghost' and 'secondary',
  // the background color changes to 't2-bg-surface-muted' and on hover to 't2-bg-surface'.
  'group-data-[intent=ghost]:data-[intent=secondary]:t2-bg-surface-muted group-data-[intent=ghost]:data-[intent=secondary]:hover:t2-bg-surface',
];

type ToolbarLinkProps = ButtonLike<
  Omit<React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Link>, 'href'> &
    LinkProps
>;

const ToolbarLink = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Link>,
  ToolbarLinkProps
>(({ className, children, intent = 'ghost', ...props }, ref) => (
  <ToolbarPrimitive.Link ref={ref} asChild>
    <LinkButton
      intent={intent}
      className={cn(toolbarLinkOrButtonClassNames, className)}
      {...props}
    >
      {children}
    </LinkButton>
  </ToolbarPrimitive.Link>
));
ToolbarLink.displayName = ToolbarPrimitive.Link.displayName;

type ToolbarButtonProps = ButtonLike<
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Button>
>;

const ToolbarButton = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Button>,
  ToolbarButtonProps
>(
  (
    { className, children, intent = 'primary', size, status, ...props },
    ref,
  ) => (
    <ToolbarPrimitive.Button ref={ref} {...props} asChild>
      <Button
        intent={intent}
        size={size}
        status={status}
        className={cn(toolbarLinkOrButtonClassNames, className)}
      >
        {children}
      </Button>
    </ToolbarPrimitive.Button>
  ),
);
ToolbarButton.displayName = ToolbarPrimitive.Button.displayName;

type ToolbarSubmitButtonProps = SubmitButtonLike<
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Button>
>;

/** When a Form submit button must be in the toolbar */
const ToolbarSubmitButton = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Button>,
  ToolbarSubmitButtonProps
>(({ className, children, intent = 'primary', size, form, ...props }, ref) => (
  <ToolbarPrimitive.Button ref={ref} {...props} asChild>
    <SubmitButton
      form={form}
      intent={intent}
      size={size}
      className={cn(toolbarLinkOrButtonClassNames, className)}
    >
      {children}
    </SubmitButton>
  </ToolbarPrimitive.Button>
));
ToolbarButton.displayName = ToolbarPrimitive.Button.displayName;

const ToolbarSupportButton = React.forwardRef<
  React.ElementRef<typeof ToolbarButton>,
  ToolbarButtonProps
>(({ children, intent = 'ghost', ...props }, ref) => {
  const intercom = useIntercom();

  return (
    <Tooltip
      disableHoverableContent
      delayDuration={0}
      content="Chat with support"
      side="bottom"
      align="center"
    >
      <ToolbarButton
        ref={ref}
        intent={intent}
        {...props}
        onClick={() => intercom.show()}
      >
        {children ?? <MessageIcon />}
      </ToolbarButton>
    </Tooltip>
  );
});
ToolbarSupportButton.displayName = 'ToolbarSupportButton';

interface ToolbarBackButtonProps extends Omit<ToolbarButtonProps, 'onClick'> {
  fallbackRoute: Route;
  tooltipContent?: string;
}

const ToolbarBackButton = React.forwardRef<
  React.ElementRef<typeof ToolbarButton>,
  ToolbarBackButtonProps
>(
  (
    {
      children,
      intent = 'ghost',
      fallbackRoute,
      tooltipContent = 'Back',
      ...props
    },
    ref,
  ) => {
    const back = useBack({ fallbackRoute });

    return (
      <Tooltip
        disableHoverableContent
        delayDuration={0}
        content={tooltipContent}
        side="bottom"
        align="center"
      >
        <ToolbarButton ref={ref} intent={intent} {...props} onClick={back}>
          {children ?? <ArrowLeftIcon />}
        </ToolbarButton>
      </Tooltip>
    );
  },
);
ToolbarBackButton.displayName = 'ToolbarBackButton';

interface ToolbarSearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange: (value: string) => void;
  value?: string;
}

const ToolbarSearchInput = React.forwardRef<
  HTMLInputElement,
  ToolbarSearchInputProps
>(
  (
    { className, value, onValueChange, placeholder = 'Search…', ...props },
    forwardedRef,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [currentValue, setCurrentValue] = React.useState(value ?? '');

    const onValueChangeDebounced = useDebouncedCallback(
      (value: string) => {
        onValueChange(value);
      },
      250,
      { maxWait: 500, trailing: true },
    );

    const onChange = React.useCallback(
      (value: string) => {
        setCurrentValue(value);
        onValueChangeDebounced(value);
      },
      [onValueChangeDebounced],
    );

    useOnEscape({
      handler() {
        if (document.activeElement !== inputRef?.current) {
          return;
        }
        onChange('');
        inputRef.current?.blur();
      },
    });

    React.useEffect(() => {
      // handle when value is changed by parent component
      if (isString(value) && !onValueChangeDebounced.isPending()) {
        setCurrentValue(value);
      }
    }, [value]);

    return (
      <ToolbarPrimitive.Button asChild>
        <Input
          ref={mergeRefs([inputRef, forwardedRef])}
          type="text"
          role="searchbox"
          value={currentValue}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
          className={cn(
            't2-pr-8 t2-rounded-full t2-border-0 focus-visible:t2-ring-offset-surface-muted',
            !currentValue.trim() && 't2-bg-transparent focus:t2-bg-surface',
            className,
          )}
          WrapperProps={{
            className: 't2-w-full',
          }}
          placeholder={placeholder}
          tabIndex={0}
          {...props}
        >
          <SearchIcon />
          {currentValue !== '' && (
            <Button
              className="t2-absolute t2-right-1.5 t2-top-1.5"
              size="sm"
              intent="ghost"
              onClick={() => onChange('')}
            >
              <CloseIcon />
            </Button>
          )}
        </Input>
      </ToolbarPrimitive.Button>
    );
  },
);
ToolbarSearchInput.displayName = 'ToolbarSearchInput';

export {
  toolbarVariants,
  Toolbar,
  ToolbarToggleGroup,
  ToolbarToggleItem,
  ToolbarSeparator,
  ToolbarButton,
  ToolbarSubmitButton,
  ToolbarSupportButton,
  ToolbarSearchInput,
  ToolbarLink,
  ToolbarBackButton,
};
