import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const CheckmarkIcon = createIcon('CheckmarkIcon', [
  [
    'path',
    {
      d: 'M7.75 12.75L10 15.25L16.25 8.75',
      key: '4sk32o',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default CheckmarkIcon;
