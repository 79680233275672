import { AnimatePresence, MotionConfig, m } from 'framer-motion';
import { useRouter } from 'next/router';
import * as React from 'react';
import useMeasure from 'react-use-measure';
import { useEventListener } from '~/hooks/useEventListener';
import { useIsMediumScreen } from '~/hooks/useMediaQuery';
import { useOnEscape } from '~/hooks/useOnEscape';
import { useOnOutsideInteraction } from '~/hooks/useOnOutsideInteraction';
import {
  GlobalNavigationContext,
  useGlobalNavigationCtx,
} from '~/modules/ui/components/global-navigation/global-navigation-context';
import { MenuComponentForName } from '~/modules/ui/components/global-navigation/global-navigation-list';
import { cn } from '~/modules/ui/cva';
import { Card } from '~/modules/ui/primitives/card';
import { MenuIcon } from '~/modules/ui/primitives/icon';
import { Sheet, SheetPanel, SheetTrigger } from '~/modules/ui/primitives/sheet';
import { Toolbar, ToolbarButton } from '~/modules/ui/primitives/toolbar';

function MenuComponent({ className }: { className?: string }) {
  const ctx = useGlobalNavigationCtx();
  const [ref, bounds] = useMeasure();

  const height = bounds.height === 0 ? 'auto' : bounds.height;
  const width = bounds.width === 0 ? 'auto' : bounds.width;

  return (
    <m.div
      animate={{
        height,
        width,
      }}
      initial={false}
      className={className}
    >
      <AnimatePresence
        mode="popLayout"
        initial={false}
        custom={ctx.direction.current}
      >
        <m.div
          key={ctx.visibleMenuState.name}
          variants={{
            initial: (direction: 1 | -1) => {
              return { x: `${110 * direction}%`, opacity: 0 };
            },
            active: { x: '0%', opacity: 1 },
            exit: (direction: 1 | -1) => {
              return { x: `${-110 * direction}%`, opacity: 0 };
            },
          }}
          initial="initial"
          animate="active"
          exit="exit"
          custom={ctx.direction.current}
        >
          <div ref={ref} className="t2-w-full md:t2-w-fit md:t2-max-w-64">
            <MenuComponentForName name={ctx.visibleMenuState.name} />
          </div>
        </m.div>
      </AnimatePresence>
    </m.div>
  );
}

function MobileMenuWrapper() {
  const ctx = useGlobalNavigationCtx();
  const router = useRouter();
  const [sheetOpen, setSheetOpen] = React.useState(false);

  const isDesktop = useIsMediumScreen();

  const onToggle = (open: boolean) => {
    setSheetOpen(open);
    if (!open) {
      ctx.onDismiss();
    }
  };

  useEventListener({
    type: 'resize',
    listener: () => onToggle(false),
    enabled: sheetOpen && isDesktop,
  });

  React.useEffect(() => {
    const handler = () => onToggle(false);

    if (!isDesktop) {
      router.events.on('routeChangeComplete', handler);
      return;
    }

    return router.events.off('routeChangeComplete', handler);
  }, [isDesktop, router.pathname]);

  return (
    <Toolbar intent="ghost" className="md:t2-hidden">
      <Sheet open={sheetOpen} onOpenChange={onToggle}>
        <SheetTrigger asChild>
          <ToolbarButton intent="secondary">
            <MenuIcon />
          </ToolbarButton>
        </SheetTrigger>
        <SheetPanel
          side="left"
          className="t2-bg-surface t2-p-1.5 sm:t2-p-1.5 t2-rounded-3xl t2-max-w-sm sm:t2-max-w-sm"
          scrollAreaClassName="t2-bg-surface-muted t2-rounded-[28px]"
          closeButtonClassName="t2-absolute t2-top-3 t2-right-3 t2-bg-transparent t2-text-foreground-secondary hover:t2-bg-intent-secondary hover:t2-text-foreground"
          data-test-id="default-app-navigation-mobile"
        >
          <MenuComponent />
        </SheetPanel>
      </Sheet>
    </Toolbar>
  );
}

function DesktopMenuWrapper() {
  const ctx = useGlobalNavigationCtx();
  const cardRef = React.useRef<HTMLDivElement>(null);

  const isDesktop = useIsMediumScreen();
  const canDismiss = ctx.routeMenuState.name !== ctx.visibleMenuState.name;

  useOnOutsideInteraction({
    ref: cardRef,
    type: 'pointerdown',
    listener: ctx.onDismiss,
    enabled: canDismiss && isDesktop,
  });

  useOnEscape({
    handler: ctx.onDismiss,
    enabled: canDismiss && isDesktop,
  });

  return (
    <Card
      variant="muted"
      className={cn(
        't2-relative t2-overflow-hidden t2-rounded-3xl t2-p-0',
        't2-hidden md:t2-block',
      )}
      ref={cardRef}
      data-test-id="default-app-navigation"
    >
      <MenuComponent />
    </Card>
  );
}

export function GlobalNavigation() {
  return (
    <MotionConfig transition={{ duration: 0.5, type: 'spring', bounce: 0 }}>
      <GlobalNavigationContext>
        <MobileMenuWrapper />
        <DesktopMenuWrapper />
      </GlobalNavigationContext>
    </MotionConfig>
  );
}
