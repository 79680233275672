import { createContext, useContext } from 'react';
import type { AnyRuntimeConditions } from '../createRuntimeConditions';

export function createPermissionContextProvider<
  TConditions extends AnyRuntimeConditions,
>(conditions: TConditions) {
  type TResolve = TConditions['resolve'];
  type TResolveOptions = Parameters<TResolve>[0];
  type TContext = ReturnType<TResolve>;

  // biome-ignore lint/suspicious/noExplicitAny: ok
  const ctx = createContext<TContext>(undefined as any);

  function useCtx() {
    const c = useContext(ctx);
    if (c === undefined) {
      throw new Error('useCtx must be inside a Provider with a value');
    }
    return c;
  }

  function PermissionContextProvider(
    opts: TResolveOptions & {
      children: React.ReactNode;
    },
  ) {
    const { children, ...rest } = opts;
    const value = conditions.resolve(rest);

    // biome-ignore lint/suspicious/noExplicitAny: ok
    return <ctx.Provider value={value as any}>{children}</ctx.Provider>;
  }

  return [useCtx, PermissionContextProvider] as const;
}
