import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ArrowUpIcon = createIcon('ArrowUpIcon', [
  [
    'path',
    {
      d: 'M17.25 10.25L12 4.75L6.75 10.25',
      key: '27j2ho',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 19.25V5.75',
      key: 'lqptj3',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ArrowUpIcon;
