import { defineConfig } from 'cva';
import { extendTailwindMerge } from 'tailwind-merge';

export const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      'bg-color': [
        {
          bg: [
            'aubergine',
            'azure',
            'base-inverse',
            'base-muted',
            'base',
            'brand-carrotjuice-contrast',
            'brand-carrotjuice-light',
            'brand-carrotjuice',
            'brand-nicewin95-contrast',
            'brand-nicewin95-light',
            'brand-nicewin95',
            'brand-secondary-aubergine',
            'brand-secondary-azure',
            'brand-secondary-brandOrange',
            'brand-secondary-cheese',
            'brand-secondary-klein',
            'brand-secondary-lightBlue',
            'brand-secondary-lilac',
            'brand-secondary-matteRed',
            'brand-secondary-pine',
            'brand-secondary-pink',
            'brand-secondary-safari',
            'brand-secondary-seafoam',
            'brand-secondary-softBlue',
            'brand-secondary-spearmint',
            'brand-secondary-teal',
            'brand-series-0',
            'brand-series-1',
            'brand-series-10',
            'brand-series-11',
            'brand-series-12',
            'brand-series-13',
            'brand-series-2',
            'brand-series-3',
            'brand-series-4',
            'brand-series-5',
            'brand-series-6',
            'brand-series-7',
            'brand-series-8',
            'brand-series-9',
            'brand-telio-contrast',
            'brand-telio-light',
            'brand-telio',
            'brand-tolablue-contrast',
            'brand-tolablue-light',
            'brand-tolablue',
            'brandOrange',
            'cheese',
            'danger-muted',
            'danger',
            'emphasis-muted',
            'emphasis',
            'fill-material',
            'fill-shim',
            'initial',
            'inverse',
            'invoice-dark-blue',
            'invoice-dark-gray',
            'invoice-dark-green',
            'invoice-dark-red',
            'invoice-dark-yellow',
            'invoice-light-blue',
            'invoice-light-gray',
            'invoice-light-green',
            'invoice-light-red',
            'invoice-light-yellow',
            'klein',
            'lightBlue',
            'lilac',
            'matteRed',
            'pine',
            'pink',
            'primary',
            'secondary',
            'inverse',
            'transparent',
            'current',
            'safari',
            'seafoam',
            'secondary',
            'softBlue',
            'spearmint',
            'success-muted',
            'success',
            'teal',
            'tint-error',
            'tint-success',
            'tint-warning',
            'tint',
            'warning-muted',
            'warning',
          ],
        },
      ],
      'font-size': [
        {
          text: [
            'inherit',
            'title',
            'body',
            'caption',
            'headline',
            'subheadline',
            'footnote',
          ],
        },
      ],
    },
  },
  extend: {
    theme: {
      borderColor: [
        'theme',
        'azure',
        'klein',
        'teal',
        'lightBlue',
        'cheese',
        'pink',
        'matteRed',
        'brandOrange',
        'lilac',
        'softBlue',
        'aubergine',
        'seafoam',
        'spearmint',
        'pine',
        'safari',
      ],
      borderWidth: ['normal', 'hairline'],
      opacity: ['15'],
      colors: [
        'brand-secondary-azure',
        'brand-secondary-klein',
        'brand-secondary-teal',
        'brand-secondary-lightBlue',
        'brand-secondary-cheese',
        'brand-secondary-pink',
        'brand-secondary-matteRed',
        'brand-secondary-brandOrange',
        'brand-secondary-lilac',
        'brand-secondary-softBlue',
        'brand-secondary-aubergine',
        'brand-secondary-seafoam',
        'brand-secondary-spearmint',
        'brand-secondary-pine',
        'brand-secondary-safari',
        'brand-tolablue',
        'brand-tolablue-light',
        'brand-tolablue-contrast',
        'brand-carrotjuice',
        'brand-carrotjuice-light',
        'brand-carrotjuice-contrast',
        'brand-telio',
        'brand-telio-light',
        'brand-telio-contrast',
        'brand-nicewin95',
        'brand-nicewin95-light',
        'brand-nicewin95-contrast',
        'brand-series-0',
        'brand-series-1',
        'brand-series-2',
        'brand-series-3',
        'brand-series-4',
        'brand-series-5',
        'brand-series-6',
        'brand-series-7',
        'brand-series-8',
        'brand-series-9',
        'brand-series-10',
        'brand-series-11',
        'brand-series-12',
        'brand-series-13',
        'initial',
        'base',
        'base-muted',
        'base-inverse',
        'emphasis',
        'emphasis-contrast',
        'success',
        'success-contrast',
        'warning',
        'warning-contrast',
        'danger',
        'danger-contrast',
      ],
      spacing: ['gutter-left', 'gutter-right', 'sidenav'],
    },
    classGroups: {
      aspect: [{ aspect: ['mobile', 'graphic', 'page'] }],
      animate: [
        {
          animate: ['enter', 'leave', 'shake', 'shake-lg'],
        },
      ],
      transition: [{ transition: ['width'] }],
      duration: [{ duration: ['250', '400'] }],
      ease: [{ ease: ['drawer'] }],
      z: [{ z: ['-1', 'dialog', 'fullscreen', 'alert'] }],
      h: [
        {
          h: ['checkout-input', 'input-lg'],
        },
      ],
      'max-h': [
        {
          'max-h': ['checkout-input'],
        },
      ],
      w: [
        {
          w: ['sheet', 'sideNav'],
        },
      ],
      'max-w': [
        {
          'max-w': ['sheet'],
        },
      ],
      'text-color': [
        {
          text: ['primary', 'secondary', 'tertiary', 'inverse'],
        },
      ],
      'backdrop-grayscale': [{ 'backdrop-grayscale': ['50'] }],
    },
  },
});

export const { cva, cx, compose } = defineConfig({
  hooks: {
    onComplete: (className) => twMerge(className),
  },
});
