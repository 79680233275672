import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';
import { popoverClassNames } from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

interface PopoverContentProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  withPortal?: boolean;
}

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(
  (
    {
      className,
      align = 'center',
      collisionPadding = 8,
      side = 'bottom',
      sideOffset = 8,
      withPortal = true,
      ...props
    },
    ref,
  ) => {
    const Comp = withPortal ? PopoverPrimitive.Portal : React.Fragment;
    return (
      <Comp>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          side={side}
          sideOffset={sideOffset}
          collisionPadding={collisionPadding}
          className={cn(
            popoverClassNames,
            't2-w-[var(--radix-popover-trigger-width)] t2-max-h-[var(--radix-popover-content-available-height)] t2-min-w-[8rem] t2-z-[55]',
            'data-[state=open]:t2-animate-in data-[state=closed]:t2-animate-out data-[state=closed]:t2-fade-out-0 data-[state=open]:t2-fade-in-0 data-[state=closed]:t2-zoom-out-95 data-[state=open]:t2-zoom-in-95 data-[side=bottom]:t2-slide-in-from-top-2 data-[side=left]:t2-slide-in-from-right-2 data-[side=right]:t2-slide-in-from-left-2 data-[side=top]:t2-slide-in-from-bottom-2',
            className,
          )}
          {...props}
        />
      </Comp>
    );
  },
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverAnchor, PopoverContent };
