import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const BellIcon = createIcon('BellIcon', [
  [
    'path',
    {
      d: 'M17.25 12V10C17.25 7.1005 14.8995 4.75 12 4.75C9.10051 4.75 6.75 7.10051 6.75 10V12L4.75 16.25H19.25L17.25 12Z',
      key: '2hw7f9',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M9 16.75C9 16.75 9 19.25 12 19.25C15 19.25 15 16.75 15 16.75',
      key: '1mtahy',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default BellIcon;
