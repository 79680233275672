import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const DotsHorizontalIcon = createIcon('DotsHorizontalIcon', [
  [
    'path',
    {
      d: 'M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z',
      key: '1wvn52',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
  [
    'path',
    {
      d: 'M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z',
      key: '5giviu',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
  [
    'path',
    {
      d: 'M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z',
      key: '74q9np',
      fill: 'currentcolor',
      strokeWidth: 0,
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default DotsHorizontalIcon;
