import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const ShareIcon = createIcon('ShareIcon', [
  [
    'path',
    {
      d: 'M9.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H17.25C18.3546 19.25 19.25 18.3546 19.25 17.25V14.75',
      key: 'fvbzsc',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M19.25 9.25V4.75H14.75',
      key: '1ss83x',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M19 5L11.75 12.25',
      key: '1mvg7c',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default ShareIcon;
