import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const LogOutIcon = createIcon('LogOutIcon', [
  [
    'path',
    {
      d: 'M15.75 8.75L19.25 12L15.75 15.25',
      key: 'bz59t5',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M19 12H10.75',
      key: 'y1pmd8',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M15.25 4.75H6.75C5.64543 4.75 4.75 5.64543 4.75 6.75V17.25C4.75 18.3546 5.64543 19.25 6.75 19.25H15.25',
      key: 'und77l',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default LogOutIcon;
