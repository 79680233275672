import { defineConfig } from 'cva';
import { extendTailwindMerge } from 'tailwind-merge';

export type { VariantProps } from 'cva';

/**
 * This function is used withing our `cva` setup as a hook to remove
 * conflicting classNames, while possible you should never need to call
 * this yourself.
 */
const twMerge = extendTailwindMerge({
  prefix: 't2-',
  override: {
    classGroups: {
      'font-size': [
        { text: ['heading', 'title', 'subtitle', 'body', 'caption'] },
      ],
    },
  },
  extend: {
    theme: {},
    classGroups: {
      'bg-image': [
        {
          bg: ['gradient-shine'],
        },
      ],
    },
  },
});

export const {
  cva,
  cx: cn,
  compose,
} = defineConfig({
  hooks: {
    onComplete: (className) => twMerge(className),
  },
});
