import { createRuntimeConditions } from '~/permissions/lib';
import { PERMISSION_DEF } from '~/permissions/shared';

const base = createRuntimeConditions(PERMISSION_DEF).context<{
  userId: string;
}>();

const createdByCondition = base.condition<{ createdBy: string }>();

export const CLIENT_CONDITIONS = base.create({
  bill: {
    read: {
      all: createdByCondition(() => true),
      mine: createdByCondition(
        (opts) => opts.data.createdBy === opts.ctx.userId,
      ),
    },
    edit: {
      all: createdByCondition(() => true),
      mine: createdByCondition(
        (opts) => opts.data.createdBy === opts.ctx.userId,
      ),
    },
    pay: {
      all: createdByCondition(() => true),
      mine: createdByCondition(
        (opts) => opts.data.createdBy === opts.ctx.userId,
      ),
    },
  },
  recurringReceivable: {
    read: {
      all: createdByCondition(() => true),
      mine: createdByCondition(
        (opts) => opts.data.createdBy === opts.ctx.userId,
      ),
    },
    edit: {
      all: createdByCondition(() => true),
      mine: createdByCondition(
        (opts) => opts.data.createdBy === opts.ctx.userId,
      ),
    },
    pay: {
      all: createdByCondition(() => true),
      mine: createdByCondition(
        (opts) => opts.data.createdBy === opts.ctx.userId,
      ),
    },
  },
});
