import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const QuestionMarkCircleIcon = createIcon('QuestionMarkCircleIcon', [
  [
    'path',
    {
      d: 'M9.984 9A2.248 2.248 0 0 1 12 7.75a2.25 2.25 0 0 1 1.579 3.853c-.5.493-1.108 1.025-1.402 1.65M12 16.25v.01m0 2.99a7.25 7.25 0 1 1 0-14.5 7.25 7.25 0 0 1 0 14.5Z',
      key: '2ly2vb',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default QuestionMarkCircleIcon;
