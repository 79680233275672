import * as React from 'react';
import { useIMask } from 'react-imask';
import { mergeRefs } from 'react-merge-refs';
import {
  focusRingClassNames,
  inputClassNames,
} from '~/modules/ui/common-classnames';
import { cn } from '~/modules/ui/cva';
import { ColorPicker } from '~/modules/ui/primitives/color-picker';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/modules/ui/primitives/popover';

function InputWithIconWrapper({
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={cn(
        't2-relative',
        '[&>svg]:t2-absolute [&>svg]:t2-left-3 [&>svg]:t2-top-2.5 [&>svg]:t2-size-5 [&>svg]:t2-text-foreground-secondary',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  WrapperProps?: Omit<
    React.ComponentPropsWithoutRef<typeof InputWithIconWrapper>,
    'children'
  >;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, children, type = 'text', WrapperProps, ...props }, ref) => {
    const Comp = children ? InputWithIconWrapper : React.Fragment;
    return (
      <Comp {...WrapperProps}>
        {children && children}
        <input
          type={type}
          className={cn(
            inputClassNames,
            children ? 't2-pl-10' : undefined,
            className,
          )}
          ref={ref}
          {...props}
        />
      </Comp>
    );
  },
);
Input.displayName = 'Input';

interface MaskedInputProps
  extends Omit<React.ComponentPropsWithRef<typeof Input>, 'type'> {
  // biome-ignore lint/suspicious/noExplicitAny: ok
  mask: any;
  unmask?: boolean;
  radix?: string;
  thousandsSeparator?: string;
  scale?: number;
  normalizeZeroes?: boolean;
  padFractionalZeros?: boolean;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  (
    {
      className,
      mask,
      unmask = true,
      onBlur,
      onFocus,
      onChange,
      radix,
      thousandsSeparator,
      scale,
      normalizeZeroes,
      padFractionalZeros,
      value: _value,
      ref: inputRef,
      ...props
    },
    forwardedRef,
  ) => {
    const { ref, maskRef, setValue } = useIMask(
      {
        mask,
        radix,
        thousandsSeparator,
        scale,
        normalizeZeroes,
        padFractionalZeros,
        definitions: {
          h: [/^[0-9a-fA-F]{0,6}$/i],
        },
      },
      {
        onAccept: (value, _maskRef, e) => {
          if (onChange && e) {
            onChange(maybeCloneEvent(e) as React.ChangeEvent<HTMLInputElement>);
          }
        },
      },
    );

    React.useEffect(() => {
      if (typeof _value === 'string') {
        setValue(_value);
      }
    }, [_value, setValue]);

    function maybeCloneEvent(
      event:
        | InputEvent
        | React.ChangeEvent<HTMLInputElement>
        | React.FocusEvent<HTMLInputElement>,
    ) {
      if (!unmask) {
        return event;
      }
      return {
        ...event,
        target: { ...event.target, value: maskRef.current?._unmaskedValue },
      } as unknown;
    }

    return (
      <Input
        type="text"
        ref={mergeRefs([forwardedRef, ref])}
        className={cn(className)}
        onBlur={(e) =>
          onBlur &&
          onBlur(maybeCloneEvent(e) as React.FocusEvent<HTMLInputElement>)
        }
        onFocus={(e) =>
          onFocus &&
          onFocus(maybeCloneEvent(e) as React.FocusEvent<HTMLInputElement>)
        }
        defaultValue={_value}
        {...props}
      />
    );
  },
);
MaskedInput.displayName = 'MaskedInput';

const ColorInput = React.forwardRef<
  HTMLInputElement,
  Omit<React.ComponentPropsWithoutRef<typeof Input>, 'value'> & {
    value?: string;
    defaultValue?: string;
  }
>(({ className, ...props }, forwardedRef) => {
  return (
    <MaskedInput
      ref={forwardedRef}
      mask="{#}hhhhhh"
      placeholder="#000000"
      className={cn('t2-tabular-nums', className)}
      {...props}
    />
  );
});
ColorInput.displayName = 'ColorInput';

function ColorInputPopover({
  value,
  onChange,
  disabled,
}: Omit<React.ComponentPropsWithoutRef<typeof ColorPicker>, 'color'> & {
  disabled?: boolean;
  value: string;
}) {
  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          focusRingClassNames,
          't2-size-5 t2-absolute t2-top-2.5 t2-left-3 t2-rounded-sm t2-bg-surface-muted',
          'disabled:t2-cursor-not-allowed',
        )}
        style={{ backgroundColor: value }}
        disabled={disabled}
      >
        <div className="t2-sr-only">Pick color</div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        sideOffset={16}
        alignOffset={-12}
        className="t2-overflow-visible t2-w-56 t2-p-2"
      >
        <ColorPicker color={value} onChange={onChange} />
      </PopoverContent>
    </Popover>
  );
}

export { Input, MaskedInput, ColorInput, ColorInputPopover };
