import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';
import { type VariantProps, cn, cva } from '~/modules/ui/cva';
import { VStack } from '~/modules/ui/primitives/stack';

const cardVariants = cva({
  base: [
    'card t2-rounded-2xl t2-text-foreground t2-p-5',
    '[&_.card]:t2-rounded-lg',
  ],
  variants: {
    variant: {
      default: 't2-bg-surface',
      muted: 't2-bg-surface-muted',
      ghost: 't2-bg-transparent',
      inverse: 't2-bg-surface-inverse t2-text-foreground-inverse',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type CardVariants = VariantProps<typeof cardVariants>;

interface CardProps extends React.HTMLAttributes<HTMLDivElement>, CardVariants {
  asChild?: boolean;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ asChild, className, variant = 'default', ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';
    return (
      <Comp
        ref={ref}
        className={cn(cardVariants({ variant }), className)}
        {...props}
      />
    );
  },
);
Card.displayName = 'Card';

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof VStack>
>((props, ref) => <VStack ref={ref} gap="2" {...props} />);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      't2-text-title t2-text-foreground t2-text-balance',
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn(
      't2-text-body t2-text-foreground-secondary t2-text-pretty',
      className,
    )}
    {...props}
  />
));
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof VStack>
>((props, ref) => <VStack ref={ref} gap="4" {...props} />);
CardContent.displayName = 'CardContent';

export { Card, CardHeader, CardContent, CardTitle, CardDescription };
