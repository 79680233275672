import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const EyeIcon = createIcon('EyeIcon', [
  [
    'path',
    {
      d: 'M19.25 12C19.25 13 17.5 18.25 12 18.25C6.5 18.25 4.75 13 4.75 12C4.75 11 6.5 5.75 12 5.75C17.5 5.75 19.25 11 19.25 12Z',
      key: '1vn09s',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'circle',
    {
      cx: '12',
      cy: '12',
      r: '2.25',
      strokeWidth: '1.5',
      key: '1cwflp',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default EyeIcon;
