import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const RefreshIcon = createIcon('RefreshIcon', [
  [
    'path',
    {
      d: 'M11.25 4.75L8.75 7L11.25 9.25',
      key: 'q2wkop',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12.75 19.25L15.25 17L12.75 14.75',
      key: 'a7u87l',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M9.75 7H13.25C16.5637 7 19.25 9.68629 19.25 13V13.25',
      key: 'i4gmcy',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M14.25 17H10.75C7.43629 17 4.75 14.3137 4.75 11V10.75',
      key: '1luhfx',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default RefreshIcon;
