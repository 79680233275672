import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const CloseIcon = createIcon('CloseIcon', [
  [
    'path',
    {
      d: 'M17.25 6.75L6.75 17.25',
      key: '19y4gt',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M6.75 6.75L17.25 17.25',
      key: '1g4kmz',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default CloseIcon;
