import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const SpinnerIcon = createIcon('SpinnerIcon', [
  [
    'path',
    {
      d: 'M12 4.75V6.25',
      key: '14nrwm',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M17.1266 6.87347L16.0659 7.93413',
      key: 'z1v0b8',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M19.25 12L17.75 12',
      key: '1jvv3u',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M17.1266 17.1265L16.0659 16.0659',
      key: 'fq4k3h',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 17.75V19.25',
      key: '11ufhn',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M7.9342 16.0659L6.87354 17.1265',
      key: '1mpnhj',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M6.25 12L4.75 12',
      key: 'x341kp',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M7.9342 7.93413L6.87354 6.87347',
      key: '1qkfzt',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default SpinnerIcon;
