import * as React from 'react';
import { VariantProps, cva } from '~/modules/ui/cva';

const pillVariants = cva({
  base: [
    't2-inline-flex t2-rounded-full t2-px-2 t2-h-6 t2-text-body t2-leading-6 t2-whitespace-pre truncate',
    // when pill is inside an <a> tag, show pointer cursor
    't2-cursor-default [a_&]:t2-cursor-pointer',
  ],
  variants: {
    intent: {
      primary: 't2-bg-surface t2-text-foreground',
      secondary: 't2-bg-surface-muted t2-text-foreground-secondary',
      inverse: 't2-bg-surface-inverse t2-text-foreground-inverse',
      warning: 't2-bg-intent-warning t2-text-intent-warning-foreground',
      danger: 't2-bg-intent-danger t2-text-intent-danger-foreground',
    },
  },
  defaultVariants: {
    intent: 'secondary',
  },
});

type PillVariants = VariantProps<typeof pillVariants>;

interface PillProps
  extends React.ComponentPropsWithoutRef<'span'>,
    PillVariants {}

const Pill = React.forwardRef<React.ElementRef<'span'>, PillProps>(
  ({ children, className, intent, ...props }, forwardedRef) => {
    return (
      <span
        ref={forwardedRef}
        className={pillVariants({ intent, className })}
        {...props}
      >
        {children}
      </span>
    );
  },
);
Pill.displayName = 'Pill';

export { Pill };
