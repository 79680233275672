import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePublicConfig } from '~/hooks/usePublicConfig';
import { transformer } from '~/utils/transformer';

/**
 * Debug provides a component that simply displays JSON on the screen w/o
 * requiring the console to be open When debugging data from 3rd parties or our
 * own API this is sometimes much easier to use than constantly console logging
 * JSON. Using it is entirely optional. Feel free to add functionality here like
 * copy-paste or collapsed views, etc.
 *
 * Do not use it in customer facing pages.
 *
 */
export const Debug: React.FC<PropsWithChildren> = (props) => {
  const publicConfig = usePublicConfig();
  const router = useRouter();
  const hasDebug = 'debug' in router.query;

  if (
    (publicConfig.APP_ENV === 'development' ||
      publicConfig.APP_ENV === 'test') &&
    hasDebug
  ) {
    return <>{props.children}</>;
  }
  return null;
};

function DebugString(props: { code: string }) {
  return (
    <Debug>
      <div className="monospace max-h-[400px] overflow-scroll rounded border-2 border-purple-700 bg-gray-800 p-4 text-footnote text-white">
        <pre>
          <code>{props.code}</code>
        </pre>
      </div>
    </Debug>
  );
}

function DebugObject(props: { object: unknown }) {
  // Using the transformer allows us to print `undefined`/`BigInt`/etc
  let code;
  let error;
  try {
    code = JSON.stringify(transformer.serialize(props.object), null, 2);
  } catch (e) {
    error = e;
  }
  return <DebugString code={code ?? `error serializing ${error}`} />;
}

function DebugFormInner() {
  const form = useFormContext();
  const watch = form.watch();

  return (
    <DebugObject object={{ values: watch, errors: form.formState.errors }} />
  );
}
/**
 * This can be used inside any react hook form
 */
export function DebugForm() {
  return (
    <Debug>
      <DebugFormInner />
    </Debug>
  );
}
