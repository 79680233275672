import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const CircleIcon = createIcon('CircleIcon', [
  [
    'path',
    {
      d: 'M19.25 12a7.25 7.25 0 1 1-14.5 0 7.25 7.25 0 0 1 14.5 0Z',
      key: '1b5y7u',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default CircleIcon;
