import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const SearchIcon = createIcon('SearchIcon', [
  [
    'path',
    {
      d: 'M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z',
      key: '1fa2av',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default SearchIcon;
