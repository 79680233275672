interface UserLike {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
}

interface UserLikeWithEmail extends UserLike {
  email: string;
}

/**
 * Returns the full name of a user, falls back to their email if no name is available.
 */
export function getUserDisplayName(user: UserLikeWithEmail): string;
/**
 * Returns the full name of a user, falls back to the provided fallback if no name is available.
 */
export function getUserDisplayName(user: UserLike, fallback: string): string;
export function getUserDisplayName(
  opts: UserLike | UserLikeWithEmail,
  _fallback?: string,
): string {
  const fallback = _fallback ?? (opts as UserLikeWithEmail).email;

  return (
    [opts.firstName, opts.lastName]
      .map((v) => v?.trim())
      .filter(Boolean)
      .join(' ') || fallback
  );
}
