import { z } from 'zod';
import { createPermissions } from '~/permissions/lib';
import type { inferPermissionScope } from '~/permissions/lib/createPermissions';

export const PERMISSION_DEF = createPermissions({
  bill: {
    add: Boolean, // <- This does not have filters
    read: ['all', 'mine'],
    edit: ['all', 'mine'],
    pay: ['all', 'mine'],
  },
  recurringReceivable: {
    add: Boolean, // <- This does not have filters
    read: ['all', 'mine'],
    edit: ['all', 'mine'],
  },
  balance: {
    read: Boolean,
  },
  attachment: {
    add: Boolean,
    read: Boolean,
  },
  contact: {
    read: Boolean,
    add: Boolean,
    edit: Boolean,
  },
  approvalPolicy: {
    approveOrReject: Boolean,
    read: Boolean,
    edit: Boolean,
    add: Boolean,
  },
  accounting: {
    import: Boolean,
    listAccounts: Boolean,
    downloadReports: Boolean,
  },
  organization: {
    inviteUser: Boolean,
    removeUser: Boolean,
    editRole: Boolean,
    edit: Boolean,
    read: Boolean,
    onboarding: Boolean,
  },
  paymentInfo: {
    read: Boolean,
    add: Boolean,
    edit: Boolean,
  },
  integration: {
    read: Boolean,
    edit: Boolean,
  },
  viewer: {
    read: Boolean,
    edit: Boolean,
  },
  paymentRequest: {
    pay: Boolean,
  },
  referral: {
    send: Boolean,
  },
});

export type PermissionScope = inferPermissionScope<typeof PERMISSION_DEF>;

export const userRoles = ['ADMIN', 'BOOKKEEPER', 'USER'] as const;
export type UserRole = (typeof userRoles)[number];

export const userRoleSchema = z.enum(userRoles);

export const roleToLabel: Record<UserRole, string> = {
  ADMIN: 'Administrator',
  BOOKKEEPER: 'Bookkeeper',
  USER: 'User',
};

export const userRoleOptions = userRoles.map((role) => ({
  label: roleToLabel[role],
  value: role,
}));
