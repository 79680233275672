import {
  showErrorToast,
  showSuccessToast,
} from '~/modules/ui/primitives/toaster';

/**
 * Write text to the clipboard.
 */
export function writeToClipboard(
  data: string,
  opts?: {
    success?: string;
  },
) {
  // REMINDER: Firefox does not support CliboardItem
  // We can't do navigator.cliboard.write([new ClipboardItem({ 'text/plain': new Blob([data], { type: 'text/plain' }) })]
  navigator.clipboard
    .writeText(data)
    .then(() => {
      showSuccessToast(opts?.success ?? 'Copied to clipboard', {});
    })
    .catch((error) => {
      showErrorToast('Failed to copy to clipboard', {
        description: error.message,
      });
    });
}
