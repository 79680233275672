import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import { SupportButton } from '~/modules/ui/components/support-button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '~/modules/ui/primitives/card';
import { VStack } from '~/modules/ui/primitives/stack';
import { createLogger } from '~/utils/logger';

const logger = createLogger('DefaultError');
interface ErrorLike {
  message: string;
}

function DefaultError(props: { error: Error | ErrorLike }) {
  logger.error(
    'Something went wrong.',
    { error: props.error },
    props.error instanceof Error ? props.error : undefined,
  );

  return (
    <VStack
      className="t2-size-full"
      alignItems="center"
      justifyContent="center"
      data-test-id="default-error"
    >
      <Card
        variant="muted"
        className="t2-flex t2-flex-col t2-gap-6 sm:t2-max-w-md t2-w-full t2-p-6"
      >
        <CardHeader>
          <CardTitle>Something went wrong</CardTitle>
          <CardDescription>{props.error.message}</CardDescription>
        </CardHeader>
        <CardContent alignItems="end">
          <SupportButton />
        </CardContent>
      </Card>
    </VStack>
  );
}

interface Props {
  children: ReactNode;
  renderError?: (error: Error) => ReactNode;
}

interface State {
  error: Error | null;
}

/**
 * This is a component that catches errors anywhere in its child component tree
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      error,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.error) {
      return this.props.renderError ? (
        this.props.renderError(this.state.error)
      ) : (
        <DefaultError error={this.state.error} />
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary, DefaultError };
