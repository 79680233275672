import type {
  DistributedPick,
  Exact,
  KeysOfUnion,
  SetNonNullable,
  SetRequired,
} from 'type-fest';
import { isObject } from '~/utils/object';

export type DistributiveOmit<T, TKeys extends keyof T> = T extends unknown
  ? Omit<T, TKeys>
  : never;

/**
 * Extract all nullable keys from the given type.
 * (This was not provided by type-fest)
 */
export type NullableKeysOf<T extends object> = {
  [P in keyof T]-?: Extract<T[P], null | undefined> extends never ? never : P;
}[keyof T];

/**
 * Omit keys from an object.
 * @example
 * omit({foo: 'bar', baz: '1'}, 'baz'); // -> { foo: 'bar' }
 * omit({foo: 'bar', baz: '1'}, ['baz']); // -> { foo: 'bar' }
 * omit({foo: 'bar', baz: '1'}, 'foo', 'baz'); // -> {}
 * omit({foo: 'bar', baz: '1'}, ['foo', 'baz']); // -> {}
 */
export function omit<TObj extends object, TKey extends keyof TObj>(
  obj: TObj,
  ...keys: TKey[] | [TKey[]]
): DistributiveOmit<TObj, TKey> {
  const actualKeys: string[] = Array.isArray(keys[0])
    ? (keys[0] as string[])
    : (keys as string[]);

  // biome-ignore lint/suspicious/noExplicitAny: ok
  const newObj: any = Object.create(null);
  for (const key in obj) {
    if (!actualKeys.includes(key)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

type ArrayOf<T> = Array<T> | ReadonlyArray<T>;
export function pick<TObj extends object, TKey extends KeysOfUnion<TObj>>(
  obj: TObj,
  ...keys: ArrayOf<TKey> | [ArrayOf<TKey>]
): DistributedPick<TObj, TKey> {
  const actualKeys: string[] = Array.isArray(keys[0])
    ? (keys[0] as string[])
    : (keys as string[]);

  // biome-ignore lint/suspicious/noExplicitAny: ok
  const newObj: any = Object.create(null);
  for (const key in obj) {
    if (actualKeys.includes(key)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function assertDefined<T>(
  thing: (T | null) | (T | undefined) | (T | null | undefined),
  name = 'Variable',
): T {
  if (thing === null || thing === undefined) {
    throw new Error(`${name} cannot be ${thing}`);
  }
  return thing;
}

export function hasAtLeastOneFieldDefined<T>(thing: T): boolean {
  if (thing == null) {
    return false;
  }
  return Object.values(thing).some((v) => v != null);
}

/**
 * Return a string if it's a string otherwise it returns `null`
 */
export function stringOrNull(str: unknown) {
  if (typeof str === 'string') {
    return str;
  }
  return null;
}

export function filterNotNull<T>(value: T | null): value is T {
  return value !== null;
}

/**
 * @link https://raw.githubusercontent.com/NaturalCycles/js-lib/master/src/promise/pProps.ts
 * Promise.all for Object instead of Array.
 *
 * Inspired by Bluebird Promise.props() and https://github.com/sindresorhus/p-props
 *
 * Improvements:
 *
 * - Exported as { promiseProps }, so IDE auto-completion works
 * - Simpler: no support for Map, Mapper, Options
 * - Included Typescript typings (no need for @types/p-props)
 *
 * Concurrency implementation via pMap was removed in favor of preserving async
 * stack traces (more important!).
 */
export async function promiseProps<T>(
  input: {
    [K in keyof T]: T[K] | Promise<T[K]>;
  },
): Promise<{
  [K in keyof T]: Awaited<T[K]>;
}> {
  const keys = Object.keys(input);
  return Object.fromEntries(
    (await Promise.all(Object.values(input))).map((v, i) => [keys[i], v]),
  ) as {
    [K in keyof T]: Awaited<T[K]>;
  };
}

/**
 * Validate that the shape doesn't have any extra fields
 */
export type SatisfiesShape<TActualShape, TExpectedShape> =
  TActualShape extends TExpectedShape
    ? Exclude<keyof TActualShape, keyof TExpectedShape> extends never
      ? TActualShape
      : TExpectedShape
    : never;

/**
 * Create a new type that is a subset of the original type.
 */
export type SubsetOf<
  TOriginal,
  TSubset extends Exact<TOriginal, TSubset>,
> = TSubset;

/**
 * Typesafe alternative to `Boolean`
 * Helpful in conjunction with Array.filter
 *
 * const arr = [1, 2, 3, 4, 5, undefined];
 *
 *       ^? Array<number | undefined>
 *
 * const result = arr.filter(isDefined)
 *
 *          ^? Array<number>
 */
export function isDefined<T>(
  value: T | undefined | null,
): value is Required<T> {
  return value !== null && value !== undefined;
}

export type Nullable<T> = { [P in keyof T]: T[P] | null };

export async function asyncFilter<T>(
  arr: T[],
  predicate: (item: T, index: number) => Promise<boolean>,
): Promise<T[]> {
  const results = await Promise.all(
    arr.map((it, index) => predicate(it, index)),
  );
  return arr.filter((_v, index) => results[index]);
}

/**
 * Helper method to create a Record from an Array
 * i.e.
 * const array = [{id: 1, name: "one"},{id: 2, name: "two"},{id: 3, name: "three"}]
 * const record = toRecord(array, 'id')
 * record === {1: {id: 1, name: "one"}, 2: {id: 2, name: "two"}, 3: {id: 3, name: "three"}}
 */
export function toRecord<
  T extends { [K in TKey]: string | number | symbol },
  TKey extends keyof T,
>(array: T[], selector: TKey): Record<T[TKey], T> {
  return array.reduce(
    (acc, item) => ((acc[item[selector]] = item), acc),
    {} as Record<T[TKey], T>,
  );
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

/**
 * Removes diacritics (accents) from a string by normalizing to NFD form and removing combining marks
 * @example "Héllo Wörld" -> "Hello World"
 * @see https://www.30secondsofcode.org/js/s/remove-accents/
 */
export function removeAccents(text: string) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Makes a string URL friendly
 * @example "Hello World!!!" -> "hello-world"
 * @source https://gist.github.com/mathewbyrne/1280286
 */
export function slugify(text: string) {
  return removeAccents(text)
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function replaceSuffix(
  str: string,
  opts: { old: string; new: string },
): string {
  if (opts.old.length && str.endsWith(opts.old)) {
    return str.slice(0, -opts.old.length) + opts.new;
  }
  return str;
}

interface Stringable {
  toString(): string;
}

/**
 * setSearchParams allows you to merge any records key-value pairs as query
 * parameters. The key-value pairs for the key-values of the resulting
 * querystring. You can only provide singular values in the params.
 */
export function setSearchParams(
  url: string | URL,
  params: Record<string, Stringable>,
): string {
  const urlObj: URL = isString(url) ? new URL(url) : url;
  const searchParams = urlObj.searchParams;

  for (const [key, value] of Object.entries(params)) {
    searchParams.set(key, value.toString());
  }

  // Update the search params on the URL object
  urlObj.search = searchParams.toString();

  // Return the updated URL
  return urlObj.toString();
}

/**
 * no-op function that does nothing
 */
export const noop = (..._args: unknown[]) => {
  //
};

export type Maybe<T> = T | null | undefined;

// biome-ignore lint/suspicious/noExplicitAny: ok
export type AwaitedReturnType<T extends (...args: any[]) => unknown> = Awaited<
  ReturnType<T>
>;

type Repeat<
  TValue,
  TTimes extends number,
  TAggregate extends unknown[] = [],
  TCounter extends unknown[] = [],
> = TCounter['length'] extends TTimes
  ? TAggregate
  : Repeat<TValue, TTimes, [...TAggregate, TValue], [...TCounter, unknown]>;

type ZeroToTen = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

type RepeatCallback<TValue> = (index: number) => TValue;
export function repeat<TTimes extends ZeroToTen, TValue>(
  times: TTimes,
  fn: RepeatCallback<TValue>,
): Repeat<TValue, TTimes>;
export function repeat<TValue>(
  times: number,
  fn: RepeatCallback<TValue>,
): TValue[];
export function repeat<TValue>(
  times: number,
  fn: RepeatCallback<TValue>,
): TValue[] {
  return Array(times)
    .fill(null)
    .map((_, index) => fn(index));
}

export function getErrorFromUnknown(cause: unknown): Error {
  if (cause instanceof Error) {
    return cause;
  }
  if (isString(cause)) {
    return new Error(cause, { cause });
  }
  if (isObject(cause) && isString(cause.message)) {
    return new Error(cause.message, { cause });
  }

  return new Error(`Unhandled error of type '${typeof cause}'`, { cause });
}

/** Make specific keys required AND nonnullable */
export type SetNonNullableRequired<T, K extends keyof T> = SetNonNullable<
  SetRequired<T, K>,
  K
>;

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 * Handles objects, arrays, primitives, and nested structures.
 *
 * @example
 * deepEqual({a: 1, b: {c: 2}}, {a: 1, b: {c: 2}}) // -> true
 * deepEqual([1, 2, 3], [1, 2, 3]) // -> true
 * deepEqual({a: 1, b: 2}, {b: 2, a: 1}) // -> true
 * deepEqual({a: 1}, {a: '1'}) // -> false
 */
export function deepEqual(a: unknown, b: unknown): boolean {
  // Handle primitive types and referential equality
  if (a === b) {
    return true;
  }

  // Handle null/undefined cases
  if (a == null || b == null) {
    return false;
  }

  // Handle dates
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  // Handle arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((item, index) => deepEqual(item, b[index]));
  }

  // Handle objects
  if (typeof a === 'object' && typeof b === 'object') {
    const keysA = Object.keys(a as object);
    const keysB = Object.keys(b as object);

    if (keysA.length !== keysB.length) {
      return false;
    }

    return keysA.every(
      (key) =>
        Object.prototype.hasOwnProperty.call(b, key) &&
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        deepEqual((a as any)[key], (b as any)[key]),
    );
  }

  return false;
}
