import { Temporal } from '@js-temporal/polyfill';
import superjson from 'superjson';
import { PreciseDecimal } from '~/utils/precise-decimal';

superjson.registerCustom<Temporal.PlainDate, string>(
  {
    isApplicable: (v): v is Temporal.PlainDate =>
      v instanceof Temporal.PlainDate,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.PlainDate.from(v),
  },
  'Temporal.PlainDate',
);

superjson.registerCustom<Temporal.Instant, string>(
  {
    isApplicable: (v): v is Temporal.Instant => v instanceof Temporal.Instant,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.Instant.from(v),
  },
  'Temporal.Instant',
);

superjson.registerCustom<Temporal.TimeZone, string>(
  {
    isApplicable: (v): v is Temporal.TimeZone => v instanceof Temporal.TimeZone,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.TimeZone.from(v) as Temporal.TimeZone,
  },
  'Temporal.TimeZone',
);

superjson.registerCustom<Temporal.PlainTime, string>(
  {
    isApplicable: (v): v is Temporal.PlainTime =>
      v instanceof Temporal.PlainTime,
    serialize: (v) => v.toJSON(),
    deserialize: (v) => Temporal.PlainTime.from(v),
  },
  'Temporal.PlainTime',
);

superjson.registerCustom<PreciseDecimal, string>(
  {
    isApplicable: (v): v is PreciseDecimal => v instanceof PreciseDecimal,
    serialize: (v) => v.serialize(),
    deserialize: (v) => PreciseDecimal.from(v),
  },
  'PreciseDecimal',
);

export const transformer = superjson;
