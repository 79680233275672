import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const DownloadIcon = createIcon('DownloadIcon', [
  [
    'path',
    {
      d: 'M4.75 14.75V16.25C4.75 17.9069 6.09315 19.25 7.75 19.25H16.25C17.9069 19.25 19.25 17.9069 19.25 16.25V14.75',
      key: '1grp5a',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M12 14.25L12 4.75',
      key: '14iu5c',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M8.75 10.75L12 14.25L15.25 10.75',
      key: '10ed8p',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default DownloadIcon;
