import { createIcon } from '~/modules/ui/primitives/icon/create-icon';

const MailIcon = createIcon('MailIcon', [
  [
    'path',
    {
      d: 'M4.75 7.75C4.75 6.64543 5.64543 5.75 6.75 5.75H17.25C18.3546 5.75 19.25 6.64543 19.25 7.75V16.25C19.25 17.3546 18.3546 18.25 17.25 18.25H6.75C5.64543 18.25 4.75 17.3546 4.75 16.25V7.75Z',
      key: '1xklbi',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  [
    'path',
    {
      d: 'M5.5 6.5L12 12.25L18.5 6.5',
      key: '1hsxdb',
      strokeWidth: '1.5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
]);

// biome-ignore lint/style/noDefaultExport: ok
export default MailIcon;
