import { Separator } from '@radix-ui/react-separator';
import { useRouter } from 'next/router';
import { Route } from 'nextjs-routes';
import * as React from 'react';
import { useLogout } from '~/hooks/useLogout';
import { useViewer } from '~/hooks/useOrg';
import {
  MenuName,
  removeOrgResourceFromRoute,
  useGetMenuNameForPathname,
  useGlobalNavigationCtx,
} from '~/modules/ui/components/global-navigation/global-navigation-context';
import { cn } from '~/modules/ui/cva';
import { Button, LinkButton } from '~/modules/ui/primitives/button';
import {
  ArrowDownLeftIcon,
  ArrowLeftIcon,
  ArrowUpRightIcon,
  AsteriskIcon,
  AvatarHexagonalIcon,
  BankIcon,
  BellIcon,
  BookOpenIcon,
  BuildingsIcon,
  CircleIcon,
  CreditCardIcon,
  Layer3Icon,
  LogOutIcon,
  MailIcon,
  MoneyIcon,
  OpenPaneIcon,
  PlusIcon,
  RefreshIcon,
  SettingsIcon,
  SparklesIcon,
  SpinnerIcon,
  SquareIcon,
  StampIcon,
  UsersIcon,
} from '~/modules/ui/primitives/icon';
import { VStack } from '~/modules/ui/primitives/stack';
import { assertUnreachable } from '~/utils/assertUnreachable';
import { deepEqual, isString } from '~/utils/utility';

function List({
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof VStack>) {
  return <VStack gap="0.5" className={cn('t2-p-3', className)} {...props} />;
}

function ListSeparator() {
  return (
    <Separator
      className={'t2-mx-2.5 t2-h-px t2-bg-border/30 t2-my-1.5'}
      orientation="horizontal"
    />
  );
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type StaticRoute = Exclude<Route, { query: any }>['pathname'];

interface ListLinkProps
  extends React.ComponentPropsWithoutRef<typeof LinkButton> {
  matcher?: 'exact' | 'partial';
  direction?: 1 | -1;
  href: Route | StaticRoute;
}

function ListLink({
  matcher = 'partial',
  direction = 1,
  className,
  children,
  ...props
}: ListLinkProps) {
  const router = useRouter();
  const ctx = useGlobalNavigationCtx();
  const getMenuNameForPathname = useGetMenuNameForPathname();

  const pathname = isString(props.href) ? props.href : props.href.pathname;
  const query = isString(props.href) ? {} : props.href.query;

  const menu = getMenuNameForPathname(pathname);

  const isExactPathnameMatch = router.pathname === pathname;
  const isExactQueryMatch =
    !isString(props.href) && deepEqual(router.query, query);
  const isExactMatch = isExactPathnameMatch && isExactQueryMatch;

  const isPartialPathnameMatch = router.pathname.startsWith(pathname);
  const isPathnameVisibleMenu =
    ctx.routeMenuState.name === ctx.visibleMenuState.name;

  const isSelected =
    matcher === 'exact' ? isExactMatch : isPartialPathnameMatch;

  return (
    <LinkButton
      href={props.href}
      className={cn(
        't2-self-start t2-overflow-hidden t2-max-w-full [&>span]:has-[svg:first-child]:t2-pl-2',
        className,
      )}
      intent={
        typeof props.intent !== 'undefined'
          ? props.intent
          : isSelected
            ? isPathnameVisibleMenu
              ? 'primary'
              : 'secondary'
            : 'ghost'
      }
      onPointerOver={() => ctx.direction.set(direction)}
      onClick={
        isSelected && !isPathnameVisibleMenu ? () => ctx.goTo(menu) : undefined
      }
    >
      {children}
    </LinkButton>
  );
}

function ListButton({
  children,
  intent = 'ghost',
  className,
  ...props
}: React.ComponentPropsWithoutRef<typeof Button>) {
  return (
    <Button
      className={cn(
        't2-self-start t2-overflow-hidden t2-max-w-full [&>span]:has-[svg:first-child]:t2-pl-2',
        className,
      )}
      intent={intent}
      {...props}
    >
      {children}
    </Button>
  );
}

function ListPreviousButton(props: { to: MenuName }) {
  const ctx = useGlobalNavigationCtx();

  return (
    <ListButton intent="ghost" onClick={() => ctx.goTo(props.to, -1)}>
      <OpenPaneIcon />
    </ListButton>
  );
}

function GlobalDefaultMenu() {
  const viewer = useViewer();
  const ctx = useGlobalNavigationCtx();

  return (
    <List>
      <ListButton onClick={() => ctx.goTo('org-picker', 1)}>
        <SquareIcon
          style={{
            fill: viewer.organization.brandColor,
            stroke: viewer.organization.brandColor,
          }}
        />
        <span className="t2-truncate t2-block">
          {viewer.organization.displayName}
        </span>
      </ListButton>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/')} matcher="exact">
        <SparklesIcon />
        <span className="t2-truncate t2-block">For you</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/pay')}>
        <ArrowUpRightIcon />
        <span className="t2-truncate t2-block">Pay</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/get-paid')}>
        <ArrowDownLeftIcon />
        <span className="t2-truncate t2-block">Get paid</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/financing')}>
        <AsteriskIcon />
        <span className="t2-truncate t2-block">Financing</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/contacts')}>
        <BookOpenIcon />
        <span className="t2-truncate t2-block">Contacts</span>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings')}>
        <SettingsIcon />
        <span className="t2-truncate t2-block">Settings</span>
      </ListLink>
    </List>
  );
}

function GlobalOrganizationMenu() {
  const viewer = useViewer();
  const router = useRouter();
  const logout = useLogout();

  const filtered = viewer.memberships.filter(
    (it) => it.organization.type === 'COMPANY',
  );

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListSeparator />
      {filtered.map((it) => {
        return (
          <ListLink
            key={it.organization.id}
            href={removeOrgResourceFromRoute({
              pathname: router.pathname,
              query: {
                ...router.query,
                slug: it.organization.slug,
              },
            } as Route)}
            matcher="exact"
          >
            <SquareIcon
              style={{
                fill: it.organization.brandColor,
                stroke: it.organization.brandColor,
              }}
            />
            <span className="t2-truncate t2-block">
              {it.organization.displayName}
            </span>
          </ListLink>
        );
      })}
      <ListSeparator />
      {filtered.length > 1 && (
        <ListLink href="/entities">
          <BuildingsIcon />
          <span className="t2-truncate t2-block">All organizations</span>
        </ListLink>
      )}
      <ListLink href="/org/new">
        <PlusIcon />
        <span className="t2-truncate t2-block">Add organization</span>
      </ListLink>
      <ListSeparator />
      <ListButton disabled={logout.loading} onClick={() => logout.logout()}>
        {logout.loading ? (
          <SpinnerIcon className="t2-animate-spin" />
        ) : (
          <LogOutIcon />
        )}
        <span className="t2-truncate t2-block">Sign out</span>
      </ListButton>
    </List>
  );
}

function GlobalContactMenu() {
  const viewer = useViewer();
  const router = useRouter();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/contacts')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <span className="t2-truncate t2-block">Contacts</span>
      </ListLink>
      <ListSeparator />
      <ListLink
        href={viewer.pathFor('/contacts/[contactId]', {
          contactId: router.query.contactId,
        })}
        matcher="exact"
      >
        <span className="t2-truncate t2-block">Overview</span>
      </ListLink>
      <ListLink
        href={viewer.pathFor('/contacts/[contactId]', {
          contactId: router.query.contactId,
          tab: 'details',
        })}
        matcher="exact"
      >
        <span className="t2-truncate t2-block">Details</span>
      </ListLink>
    </List>
  );
}

function GlobalRecurringReceivableMenu() {
  const viewer = useViewer();
  const router = useRouter();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/get-paid/recurring')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <span className="t2-truncate t2-block">Recurring</span>
      </ListLink>
    </List>
  );
}

function GlobalPayMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink intent="secondary" href={viewer.pathFor('/pay')} direction={-1}>
        <ArrowLeftIcon />
        <span className="t2-truncate t2-block">Pay</span>
      </ListLink>
    </List>
  );
}

function GlobalGetPaidMenu() {
  const viewer = useViewer();

  if (viewer.enabledFeatureFlags.includes('11-05-recurring-invoices')) {
    return (
      <List>
        <ListPreviousButton to="default" />
        <ListSeparator />
        <ListLink href={viewer.pathFor('/get-paid')} matcher="exact">
          <CircleIcon />
          <span className="t2-truncate t2-block">Invoices</span>
        </ListLink>
        <ListLink href={viewer.pathFor('/get-paid/recurring')}>
          <RefreshIcon />
          <span className="t2-truncate t2-block">Recurring</span>
        </ListLink>
      </List>
    );
  }

  return <GlobalDefaultMenu />;
}

function GlobalInvoiceMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink
        intent="secondary"
        href={viewer.pathFor('/get-paid')}
        direction={-1}
      >
        <ArrowLeftIcon />
        <span className="t2-truncate t2-block">Get Paid</span>
      </ListLink>
    </List>
  );
}

function GlobalSettingsMenu() {
  const viewer = useViewer();

  return (
    <List>
      <ListPreviousButton to="default" />
      <ListLink intent="secondary" href={viewer.pathFor('/')} direction={-1}>
        <ArrowLeftIcon />
        <span className="t2-truncate t2-block">Back to dashboard</span>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings/profile')}>
        <AvatarHexagonalIcon />
        <span className="t2-truncate t2-block">Your profile</span>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings')} matcher="exact">
        <SettingsIcon />
        <span className="t2-truncate t2-block">General</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/tiers')}>
        <Layer3Icon />
        <span className="t2-truncate t2-block">Tiers</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/members')}>
        <UsersIcon />
        <span className="t2-truncate t2-block">Members</span>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings/accounts')}>
        <BankIcon />
        <span className="t2-truncate t2-block">Bank accounts</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/cards')}>
        <CreditCardIcon />
        <span className="t2-truncate t2-block">Cards</span>
      </ListLink>
      <ListSeparator />
      {viewer.can('accounting.listAccounts') && (
        <ListLink href={viewer.pathFor('/settings/accounting')}>
          <RefreshIcon />
          <span className="t2-truncate t2-block">Accounting</span>
        </ListLink>
      )}
      <ListLink href={viewer.pathFor('/settings/approvals')}>
        <StampIcon />
        <span className="t2-truncate t2-block">Approvals</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/reminders')}>
        <BellIcon />
        <span className="t2-truncate t2-block">Reminders</span>
      </ListLink>
      <ListLink href={viewer.pathFor('/settings/email-forwarding')}>
        <MailIcon />
        <span className="t2-truncate t2-block">Email forwarding</span>
      </ListLink>
      <ListSeparator />
      <ListLink href={viewer.pathFor('/settings/refer')}>
        <MoneyIcon />
        <span className="t2-truncate t2-block">Refer a company</span>
      </ListLink>
    </List>
  );
}

export function MenuComponentForName({ name }: { name: MenuName }) {
  switch (name) {
    case 'default':
      return <GlobalDefaultMenu />;
    case 'org-picker':
      return <GlobalOrganizationMenu />;
    case 'pay':
      return <GlobalPayMenu />;
    case 'get-paid':
      return <GlobalGetPaidMenu />;
    case 'invoice':
      return <GlobalInvoiceMenu />;
    case 'settings':
      return <GlobalSettingsMenu />;
    case 'contact':
      return <GlobalContactMenu />;
    case 'recurring':
      return <GlobalRecurringReceivableMenu />;
    default:
      assertUnreachable(name);
  }
}
