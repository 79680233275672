import { useEffect } from 'react';
import { useEventCallback } from './useEventCallback';

/**
 * @link https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-event-listener.ts
 */
export function useEventListener<TType extends keyof WindowEventMap>(props: {
  element?: HTMLElement | Document | Window | EventTarget | null | undefined;
  type: TType;
  listener: (event: WindowEventMap[TType]) => void;
  options?: boolean | AddEventListenerOptions;
  enabled?: boolean;
}) {
  const { type, options, enabled } = props;

  const listener = useEventCallback(props.listener);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const element = props.element ?? window;
    function handler(event: WindowEventMap[TType]) {
      listener(event);
    }

    // biome-ignore lint/suspicious/noExplicitAny: ok
    element.addEventListener(type, handler as any, options);
    // biome-ignore lint/suspicious/noExplicitAny: ok
    return () => element.removeEventListener(type, handler as any, options);
  }, [props.element, type, options, enabled, listener]);
}
